import {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { login } from './AuthApiCalls'

const Login = () => {
  // fields needed for login
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // success indicator and message of signup request
  const [success, setSuccess] = useState(null)
  const navigate = useNavigate()
  
  // POST request that occurs on click of 'Submit' button
  const onSubmit = async () => {
    const body = {email: email, password: password}
    const [status, response_json] = await login(body)

    if (status === 200) {
      setSuccess(true)
      navigate('/fantasy/account')
    } else {
      console.log(response_json)
      setSuccess(false)
    }
  }

  return (
    <div className='grow-vert-elo'>
      <div className='auth-body-container'>
        <h1 style={{background: 'none', marginBottom: '0px'}}>Login</h1>
        Sign in using your email and password.
        <div className='auth-field-container'>{success === false && 'Email/Password combination incorrect'}</div>
        
        <input 
          className='form-input' 
          placeholder='Email' 
          onChange={event => setEmail(event.target.value)}/>
        <input 
          type='password'
          className='form-input' 
          placeholder='Password' 
          onChange={event => setPassword(event.target.value)}
        />
        <input className='btn' type='submit' onClick={onSubmit}/>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Link to='/fantasy/signup' style={{color: 'blue', textDecoration: 'underline'}}>Don't have an account? Signup</Link>
          <Link to='/fantasy/forgotpassword' style={{color: 'red', textDecoration: 'underline'}}>Forgot your password? Reset here</Link>
        </div>
      </div>
    </div>
  )
}

export default Login
