import React from 'react'

const EventHome = () => {
  return (
    <h2 style={{display: 'flex', justifyContent: 'center', color: 'white'}}>
      Click one of the above rounds for the full results and insights!
    </h2>
  )
}

export default EventHome
