import immaculate from '../images/immaculate.png'
import leadtree from '../images/leadtree.png'
import leadinsights from '../images/leadinsights.png'
import ap1 from '../images/ap1.png'
import ap2 from '../images/ap2.png'
import ap3 from '../images/ap3.png'
import ap4 from '../images/ap4.png'

const Home = () => {
  return (
    <div>
      <div className='home'>
        <div className='home-text'>Climbing.</div>
        <div className='home-text'>Stats.</div>
        <div className='home-text' style={{marginBottom: '10vh'}}>Fantasy.</div>
      </div>
      <div className='home-bottom'>
        <div className='home-bottom-row-2'>
          <div className='home-bottom-text-2'>
            <h1>Athlete Profiles</h1>
            Comprehensive profiles for each athlete featuring
            Elo Ratings:
            a one number metric for measuring climbing performance. <br />
            Interactive Elo vs. Time graph, general statistics, 
            and full results for both boulder and lead disciplines.
          </div>
          <div className='grow-vert-center'>
            <img className='ap1' src={ap1} alt=''></img>
            <div className='grow-horiz-home'>
              <img className='ap2' src={ap2} alt=''></img>
              <img className='ap3' src={ap3} alt=''></img>
            </div>
            <img className='ap4' src={ap4} alt=''></img>
          </div>
        </div>

        <div className='home-bottom-row'>
          <img className='immaculate' src={immaculate} alt=''></img>
          <div className='home-bottom-text'>
            <h1>Results</h1>
            Easy to interpret scores from every round of the competition. <br/>
            (Natalia Grossman (USA) with the rare Immaculate Round at Salt Lake City)
          </div>
        </div>

        <div className='home-bottom-row-2'> 
          <div className='home-bottom-text-2'>
            <h1>Round Insights</h1>
            Advanced insights for both Boulder and Lead across every round. <br />
            (2019 Women's Lead Qualifiers from Chamonix (FRA))
          </div>
          <img className='lead-tree-img' src={leadtree} alt=''></img>
          <img className='lead-insights' src={leadinsights} alt=''></img>
        </div>

        <div style={{display:'flex', justifyContent:'center'}}>
          <div className='home-bottom-row-3'>
            <h1>Coming Soon</h1>
            <p>More Events | Additional Metrics | Stat Leaders | Predictions</p>
          </div>
        </div>
        <div style={{ display: 'flex', 
                      justifyContent: 'center', 
                      marginTop:'150px',
                      marginBottom: '2vw'}}>A project by Cooper Chia</div>
      </div>
    </div>
  )
}

export default Home
