import missing_headshot from '../../images/missing_headshot.jpeg'
import AthleteProfile from '../profile/AthleteProfile'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal'
import { useState } from 'react'

const AthletesTableBody = ({ aths, updateTeam, plus, discipline }) => {
  const [modalAth, setModalAth] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = (ath) => {
    setModalAth(ath)
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  let rowCount = 0

  return (
    <tbody>
      {aths.map((ath) => (
        <tr
          className="draft-table-row"
          style={{
            background:
              rowCount++ % 2 === 0 ? 'rgb(13, 13, 51)' : 'rgb(35, 35, 92)',
          }}
        >
          <td
            style={{
              borderTopLeftRadius: '0.5vw',
              borderBottomLeftRadius: '0.5vw',
            }}
            onClick={() => openModal(ath)}
          >
            <img
              className="profile-headshot-small"
              src={ath.photo_url ? ath.photo_url : missing_headshot}
              alt=""
            />
          </td>
          <td style={{ textAlign: 'left' }} onClick={() => openModal(ath)}>
            {ath.firstname + ' ' + ath.lastname}
          </td>
          <td onClick={() => openModal(ath)}>
            {ath[`elo_cur_${discipline}`]
              ? Math.round(ath[`elo_cur_${discipline}`])
              : '-'}
          </td>
          <td onClick={() => openModal(ath)}>
            {ath[`elo_max_${discipline}`]
              ? Math.round(ath[`elo_max_${discipline}`])
              : '-'}
          </td>
          <td onClick={() => openModal(ath)}>{ath.salary}</td>
          <td
            style={{
              borderTopRightRadius: '0.5vw',
              borderBottomRightRadius: '0.5vw',
            }}
          >
            <FontAwesomeIcon
              icon={plus ? faPlusCircle : faMinusCircle}
              style={{ color: plus ? 'green' : 'red' }}
              onClick={() => updateTeam(ath)}
            />
          </td>
        </tr>
      ))}
      {!plus &&
        Array(6 - aths.length)
          .fill(0)
          .map((blank) => (
            <tr
              style={{ background: rowCount++ % 2 === 1 ? 'gray' : '#474a4d' }}
            >
              <td
                style={{
                  borderTopLeftRadius: '0.5vw',
                  borderBottomLeftRadius: '0.5vw',
                }}
              >
                <img
                  className="profile-headshot-small"
                  src={missing_headshot}
                  alt=""
                />
              </td>
              <td>Empty</td>
              <td></td>
              <td></td>
              <td></td>
              <td
                style={{
                  borderTopRightRadius: '0.5vw',
                  borderBottomRightRadius: '0.5vw',
                }}
              ></td>
            </tr>
          ))}

      <Modal
        className="modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        preventScroll={true}
        appElement={document.body}
      >
        <AthleteProfile athData={modalAth} />
      </Modal>
    </tbody>
  )
}

export default AthletesTableBody
