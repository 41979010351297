import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { signup } from './AuthApiCalls'
import { fieldError } from './AuthHelpers'

const Signup = () => {
  // fields needed for signup
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // success indicator and message of signup request
  const [success, setSuccess] = useState(null)
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  // POST request that occurs on click of 'Submit' button
  const onSubmit = async () => {
    if (username === '') {
      setErrors({'username': ['can\'t be blank']})
      return
    } else if (email === '') {
      setErrors({'email': ['can\'t be blank']})
      return
    } else if (password === ''){
      setErrors({'password': ['can\'t be blank']})
      return
    }

    const body = {username: username, email: email, password: password}
    const [status, response_json] = await signup(body)

    if (status === 201) {
      setSuccess(true)
      navigate('/fantasy/account')
    } else {
      setSuccess(false)
      'errors' in response_json && setErrors(response_json.errors)
    }
  }

  return (
    <div className='grow-vert-elo'>
      <div className='auth-body-container'>
        <h1 style={{background: 'none', marginBottom: '0px'}}>Sign up</h1>
        Create an account to participate in fantasy events.
        <div className='auth-field-container'>
          <input className='form-input' placeholder='Username' onChange={event => setUsername(event.target.value)}/>
          {fieldError(success, errors, 'username')}
        </div>
        <div className='auth-field-container'>
          <input className='form-input' placeholder='Email' onChange={event => setEmail(event.target.value)}/>
          {fieldError(success, errors, 'email')}
        </div>
        <div className='auth-field-container'>
          <input 
            type='password'
            className='form-input' 
            placeholder='Password' 
            onChange={event => setPassword(event.target.value)}
          />
          {fieldError(success, errors, 'password')}
        </div>
        <input className='btn' type='submit' onClick={onSubmit}/>
        <Link to='/fantasy/login' style={{color: 'blue', textDecoration: 'underline'}}>Already have an account? Login</Link>
      </div>
    </div>
  )
}

export default Signup
