import EloScatter from "./EloScatter"
import StatDropdown from "./StatDropdown"
import StatGlossary from "./StatGlossary"
import missing_headshot from '../../images/missing_headshot.jpeg'

import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { getLeaders } from "../../ApiCalls"

const LeaderStats = ({gender}) => {
  const [statDisc, setStatDisc] = useState('Boulder')
  const toggleStatDisc = (disc) => setStatDisc(disc)
  const discs = ['Boulder', 'Lead']

  const [activeStat, setActiveStat] = useState('CUR')
  const toggleActiveStat = (stat) => setActiveStat(stat)
  const statHeaders = ['NUM', 'CUR', 'PEAK', 'WIN', 'WIN%', 'POD', 'POD%', 'FIN', 'FIN%', 'SF', 'SF%']

  const [open, setOpen] = useState(false)
  const openMenu = () => setOpen(true)
  const closeMenu = () => setOpen(false)

  let [athData, setAthData] = useState([])
  useEffect(() => {
    const getAthLeaders = async () => {
      const [status, response_json] = await getLeaders(gender)
      if (status === 200) {
        response_json.forEach(ath => ath.id = ath.athlete_id)
        setAthData(response_json)
      }
    }
    getAthLeaders()
  }, [gender])

  // create a mapping from ath-id to all ath data
  let map = {}
  athData.forEach(ath => map[ath.id] = ath)

  // get the top 50 athletes to be scatter-plotted
  const boulder_cur = athData
    .filter(ath => 'rank_cur_boulder' in ath)
    .sort((a, b) => a.rank_cur_boulder - b.rank_cur_boulder)
    .map(ath => ath.id)
    .slice(0, 50)
  const lead_cur = athData
    .filter(ath => 'rank_cur_lead' in ath)
    .sort((a, b) => a.rank_cur_lead - b.rank_cur_lead)
    .map(ath => ath.id)
    .slice(0, 50)


  // get scatter plot data for boulder_cur
  const [names_b_cur, xy_b_cur] = get_scatter_data(map, boulder_cur, 'boulder')
  // get scatter plot data for lead_cur
  const [names_l_cur, xy_l_cur] = get_scatter_data(map, lead_cur, 'lead')

  // add stats to athData
  athData = calc_stats(athData)
  const curDisc = statDisc.toLowerCase()
  // mapping from stat header to field in map
  const statMap = {
    NUM: `n_${curDisc}`,
    CUR: `elo_cur_${curDisc}`,
    PEAK: `elo_max_${curDisc}`,
    WIN: `wins_${curDisc}`,
    'WIN%': `win_rate_${curDisc}`,
    POD: `podiums_${curDisc}`,
    'POD%': `podium_rate_${curDisc}`,
    FIN: `finals_${curDisc}`,
    'FIN%': `finals_rate_${curDisc}`,
    SF: `semis_${curDisc}`,
    'SF%': `semis_rate_${curDisc}`
  }
  // sort athletes based on active stat
  const active = (all_results, discipline) => {
    for (let i = 0; i < all_results.length; i++) {
      if (all_results[i].discipline === discipline) {
        return parseInt(all_results[i].season) > 2019
          && all_results.filter(res => res.discipline === discipline).length >= 3
      }
    }
    return false
  }

  let curAthData = athData.filter(ath => `elo_max_${curDisc}` in ath)
  if (activeStat === 'CUR') {
    curAthData = athData.filter(ath => active(ath.all_results, curDisc))
  }
  curAthData.sort((a, b) => b[statMap[activeStat]] - a[statMap[activeStat]])
  const top_50 = curAthData.slice(0, 50)
  
  // defined so that each row can alternate colors
  let rowCount = 0

  return (
    <div className="grow-vert-elo">
      <div className='stat-body-container'>
        <div onMouseEnter={openMenu} onMouseLeave={closeMenu} >
          <div className='stats-drop-btn' style={{marginTop: '20px'}}>
            Stats - {statDisc}
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {open && <StatDropdown data={discs} close={closeMenu} setCurStat={toggleStatDisc} />}
        </div>
        <div style={{ maxHeight: '10000px', overflow: 'scroll' }}>
        <table>
          <thead style={{background: 'gray'}}>
            <tr>
              <th>RK</th>
              <th></th>
              <th style={{textAlign: 'left'}}>NAME</th>
              {statHeaders.map(header => <th style={{cursor: 'pointer'}} onClick={e => toggleActiveStat(header)}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            
            {top_50.map(ath =>
              <tr style={{ background: rowCount++ % 2 === 0 ? 'lightblue' : 'rgb(195, 222, 231)', textAlign:'center'}}>
                <td className="rank-number-big">
                  {top_50.indexOf(ath) + 1}
                </td>
                <td style={{width: '60px', textAlign: 'center'}}>
                  <img className='profile-headshot-small' src={ath.photo_url ? ath.photo_url : missing_headshot} alt='' />
                </td>
                <td style={{textAlign: 'left'}}>
                  {<Link to={`/athletes/${ath.id}`} style={{color: 'blue'}}>{ath.firstname + ' ' + ath.lastname}</Link>}
                </td>
                <td style={{ background: activeStat === 'NUM' && 'lightskyblue' }}>{Math.round(ath[`n_${curDisc}`])}</td>
                <td style={{ background: activeStat === 'CUR' && 'lightskyblue'}}>{Math.round(ath[`elo_cur_${curDisc}`])}</td>
                <td style={{ background: activeStat === 'PEAK' && 'lightskyblue' }}>{Math.round(ath[`elo_max_${curDisc}`])}</td>
                <td style={{ background: activeStat === 'WIN' && 'lightskyblue' }}>{ath[`wins_${curDisc}`]}</td>
                <td style={{ background: activeStat === 'WIN%' && 'lightskyblue' }}>{ath[`win_rate_${curDisc}`].toFixed(1)}</td>
                <td style={{ background: activeStat === 'POD' && 'lightskyblue' }}>{ath[`podiums_${curDisc}`]}</td>
                <td style={{ background: activeStat === 'POD%' && 'lightskyblue' }}>{ath[`podium_rate_${curDisc}`].toFixed(1)}</td>
                <td style={{ background: activeStat === 'FIN' && 'lightskyblue' }}>{ath[`finals_${curDisc}`]}</td>
                <td style={{ background: activeStat === 'FIN%' && 'lightskyblue' }}>{ath[`finals_rate_${curDisc}`].toFixed(1)}</td>
                <td style={{ background: activeStat === 'SF' && 'lightskyblue' }}>{ath[`semis_${curDisc}`]}</td>
                <td style={{ background: activeStat === 'SF%' && 'lightskyblue' }}>{ath[`semis_rate_${curDisc}`].toFixed(1)}</td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
        <StatGlossary/>
      </div>
      <div className='grow-horiz-elo' style={{marginTop: '20px'}}>
        <div className='discipline-body-container' style={{maxWidth: '550px'}}>
          <h1 style={{ textDecorationLine: 'underline', margin: '0px' }}>Boulder</h1>
          <EloScatter names={names_b_cur} data={xy_b_cur} color='rgb(53, 173, 163)'/>
          
        </div>
        <div className='discipline-body-container' style={{maxWidth: '550px'}}>
          <h1 style={{ textDecorationLine: 'underline', margin: '0px' }}>Lead</h1>
          <EloScatter names={names_l_cur} data={xy_l_cur} color='rgb(116, 179, 231)' />
          
        </div>
      </div>
    </div>
    
  )
}

const get_scatter_data = (map, ranks, disc) => {
  let names = {}
  let data = []
  ranks.forEach(id => {
    // add names of athletes to labels
    const name = map[id].firstname + ' ' + map[id].lastname
    // add (x,y) data (num contests, elo)
    let pair = {}
    pair['y'] = map[id].all_results
      .filter(event => event.discipline === disc && event.rank !== null).length
    const elo = map[id][`elo_cur_${disc}`]
    pair['x'] = Math.round(elo * 10) / 10

    // TODO: need better way of accessing name in labels than this mapping
    names[pair['x']] = name
    data.push(pair)
  })
  return [names, data]
}

// add fields to the athlete objects for various stats
const calc_stats = (athData) => {
  const discs = ['boulder', 'lead']
  athData.forEach(ath => {
    discs.forEach(disc => {
      if (!(`elo_cur_${disc}` in ath)) {
        ath[`elo_cur_${disc}`] = 0
      }
      if (!(`elo_max_${disc}` in ath)) {
        ath[`elo_max_${disc}`] = 0
      }
      // first filter results to the discipline
      const results = ath.all_results.filter(event => event.discipline === disc && event.rank !== null)
      // calculate num participations
      ath[`n_${disc}`] = results.length
      // calculate wins
      ath[`wins_${disc}`] = results.filter(event => event.rank === 1).length
      ath[`win_rate_${disc}`] = results.length > 0 ? ath[`wins_${disc}`] / results.length * 100 : 0
      // calc podiums
      ath[`podiums_${disc}`] = results.filter(event => event.rank <= 3).length
      ath[`podium_rate_${disc}`] = results.length > 0 ? ath[`podiums_${disc}`] / results.length * 100 : 0
      // calc finals
      let thresh = disc === 'boulder' ? 6 : 8
      ath[`finals_${disc}`] = results.filter(event => event.rank <= thresh).length
      ath[`finals_rate_${disc}`] = results.length > 0 ? ath[`finals_${disc}`] / results.length * 100 : 0
      // calc semis
      thresh = disc === 'boulder' ? 20 : 26
      ath[`semis_${disc}`] = results.filter(event => event.rank <= thresh).length
      ath[`semis_rate_${disc}`] = results.length > 0 ? ath[`semis_${disc}`] / results.length * 100 : 0
    })
  })
  return athData
}

export default LeaderStats
