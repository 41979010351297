import {Link} from 'react-router-dom'

// represents the page active when a year is specified for boulder/lead
const Year = ({eventsB, eventsL, fantasyEvents, replace, year}) => {

  // get the date of an event
  const getDate = (event_id) => {
    if (fantasyEvents && fantasyEvents.length > 0) {
      const event = fantasyEvents.filter(event => event.event_id === parseInt(event_id))[0]
      const date = new Date(event.start_date)
      date.setDate(date.getDate() + 1)
      return date
    }
    return new Date()
  }

  const dateToString = (date) => {
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  const bKeys = Object.keys(eventsB)
  const lKeys = Object.keys(eventsL)
  bKeys.sort((a, b) => getDate(eventsB[a]) - getDate(eventsB[b]))
  lKeys.sort((a, b) => getDate(eventsL[a]) - getDate(eventsL[b]))

  return (
    <div className='grow-horiz-events'>
      {bKeys.length > 0 && 
      <div className='grow-vert-events'>
        <h1 style={{marginBottom: '0px', background: 'black', padding: '1.5vh 2vw', borderRadius: '1.5vh'}}>
          Boulder - {year}
        </h1>
        <div className='year'>
          {bKeys.map(event => 
            <Link key={event} to={'boulder/' + eventsB[event]}>
              <div className={year >= 2022 ? 'event-card' : replace(event) + year % 2000 + 'b'}>
                <div className='link-text'>{event}</div>
                <div>{dateToString(getDate(eventsB[event]))}</div>
              </div>
            </Link>
          )}
        </div>
      </div>}
      {lKeys.length > 0 && 
      <div className='grow-vert-events'>
        <h1 style={{marginBottom: '0px', background: 'black', padding: '1.5vh 2vw', borderRadius: '1.5vh'}}>
          Lead - {year}
        </h1>
        <div className='year'>
          {lKeys.map(event => 
            <Link key={event} to={'lead/' + eventsL[event]}>
              <div className={year >= 2022 ? 'event-card' : replace(event) + year % 2000 + 'l'}>
                <div className='link-text'>{event}</div>
                <div>{dateToString(getDate(eventsL[event]))}</div>
              </div>
            </Link>
          )}
        </div>
      </div>}
    </div>
    
  )
}

export default Year

