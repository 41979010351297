import { Link } from 'react-router-dom'
import { Outlet } from 'react-router'

// display results and insights from a single location/event
const Event = ({year, discipline, event, rounds}) => {
  const getClassName = (round) => round.stage % 2 === 1 ? 'w-link' : 'm-link'

  const buttons = ['Q', 'Q', 'S', 'S', 'F', 'F']
  let i = -1

  return (
    <div>
      <div className='grow-vert-event'>
        <div className='event-title'>{event} - {discipline} ({year})</div>
        <nav className='grow-horiz'>
          {rounds.map(round => 
            ++i >= 0 &&
            <Link className={getClassName(round)} to={'' + round.round_id}>
              {buttons[i]}
            </Link>
          )}
        </nav>
      </div>
      <Outlet/>
    </div>
  )
}

export default Event
