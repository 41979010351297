import Scores from './score/Scores'
import Insights from './insights/Insights'
import ScoresL from './score/ScoresL'
import InsightsL from './insights/InsightsL'
import LeadTree from './insights/LeadTree'
import { useEffect, useState } from 'react'
import { getRound } from '../ApiCalls'

const Round = ({ roundId, stage, discipline }) => {
  const [roundData, setRoundData] = useState(null)

  useEffect(() => {
    const getSingleRound = async () => {
      const [status, response_json] = await getRound(roundId)
      if (status === 200) {
        setRoundData(response_json)
      }
    }
    getSingleRound()
  }, [roundId])

  return (
    roundData && (
      <div className="round">
        <div className="results">
          {discipline === 'b' && <Scores roundData={roundData} stage={stage} />}
          {discipline === 'l' && (
            <ScoresL roundData={roundData} stage={stage} />
          )}
        </div>
        {discipline === 'l' && (
          <LeadTree roundData={roundData} stage={stage}></LeadTree>
        )}
        <div className="insights">
          {discipline === 'b' && (
            <Insights roundData={roundData} stage={stage} />
          )}
          {discipline === 'l' && <InsightsL roundData={roundData} />}
        </div>
      </div>
    )
  )
}

export default Round
