import { useState } from "react"
import Button from "../Button";
import DropdownMenu from "./DropdownMenu";

const NavItem = ({text, sub, color}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open)

    

  return (
    <li key={text} className="nav-item" onMouseEnter={toggleOpen} onMouseLeave={toggleOpen}>
      
      <Button className='btn' color={color} text={text}/>

      {open && <DropdownMenu link={text} sub={sub} color={color} onClick={toggleOpen}/>}
    </li>
  )
}

export default NavItem
