// check if there is an error message to display for a given field
export function fieldError(success, errors, field) {
  if (success) return ''
  return field in errors ? field + ' ' + errors[field][0] : ''
}

// check if there is an error message to display for a password update field
export function fieldErrorPassword(success, errors, field) {
  if (success !== false) return ''
  // errors is of type string
  if (typeof errors === 'string') {
    if (errors === 'Current password incorrent!' && field === 'current') return errors 
    return errors.includes(field) ? 'can\'t be blank' : ''
  }
  return field !== 'current' ? 'passwords must match' : ''
}