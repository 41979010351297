import { Link } from 'react-router-dom'
import { Outlet } from 'react-router'

const Leaders = () => {
  return (
    <div>
      <div className='grow-vert-event'>
        <Link className='event-title' to='/leaders'>Leaders</Link>
        <nav className='grow-horiz'>
          <Link className='m-link' to='men'>M</Link>
          <Link className='w-link' to='women'>W</Link>
        </nav>
      </div>
      <Outlet/>
    </div>
  )
}

export default Leaders
