import AthleteScoreFinal from "./AthleteScoreFinal"
import AthleteScoreSemi from "./AthleteScoreSemi"
import AthleteScoreQuali from "./AthleteScoreQuali"

const Scores = ({roundData, stage}) => {
  let count = 0

  const title = ['Men\'s Qualifiers', 'Women\'s Qualifiers', 'Men\'s Semi-Finals',
    'Women\'s Semi-Finals', 'Men\'s Finals', 'Women\'s Finals']

  if (!('ranking' in roundData)) {
    return (
      <div>
        <h1>Scoreboard - {title[stage]}</h1>
        <p>Waiting for round to begin...</p>
      </div>
    )
  }

  return (
    <div>
      <h1>Scoreboard - {title[stage]}</h1>
      {stage <= 1 && roundData.ranking.map(athleteData =>
        <AthleteScoreQuali athlete={athleteData} count={count++}/>
      )}
      {stage >= 2 && stage <= 3 && roundData.ranking.map(athleteData =>
        <AthleteScoreSemi athlete={athleteData} count={count++}/>
      )}
      {stage >= 4 && roundData.ranking.map(athleteData =>
        <AthleteScoreFinal athlete={athleteData} count={count++}/>
      )}
    </div>
  )
}

export default Scores
