import React from 'react'
import AthleteScoreB from './AthleteScoreB'


const AthleteScoreFinal = ({athlete, count}) => {
  // function for calculating the background color of the scorecard based on rank
  const color = (rank) => {
    if (rank !== null && rank === 1) return 'rgb(177, 160, 13)'
    else if (rank !== null && rank === 2) return 'silver'
    else if (rank !== null && rank === 3) return 'rgb(178, 81, 30)'
    return count % 2 === 1 ? 'steelblue' : 'rgba(70, 131, 180, 0.7)'
  }

  return (
    <AthleteScoreB athlete={athlete} color={color}/>
  )
}

export default AthleteScoreFinal
