import Modal from "react-modal"

const ModalBuild = ({modalIsOpen, closeModal}) => {
  return (
    <Modal
      className='modal-rules' 
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      preventScroll={true}
      appElement={document.body}
    >
      <div className='grow-vert-container' style={{width: '35vw'}}>
        <h2 style={{marginTop: 0}}>Rules</h2>
        <ul style={{listStyle: 'inherit', marginLeft: '1vw', marginRight: 'auto'}}>
          <li>
            Build your team of athletes from the bank of available players
          </li>
          <li>
            Stay within the salary constraints of this event
          </li>
          <li>
            Click on an athlete for more detailed stats
          </li>
          <li>
            Save your team when ready (you will be able to update later)
          </li>
        </ul>
      </div>
      <div className='grow-vert-container' style={{width: '35vw'}}>
        <h2 style={{marginTop: 0}}>Scoring</h2>
        <ul style={{listStyle: 'inherit', marginLeft: '1vw', marginRight: 'auto'}}>
          <li>
            Each athlete can score a maximum of 30 points per round of the competition
          </li>
          <li>
            A round score is determined by the athlete's rank in that round
          </li>
          <li>
            Score more points to finish higher on the leaderboard
          </li>
        </ul>
      </div>
    </Modal>
  )
}

export default ModalBuild
