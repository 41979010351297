import missing_headshot from '../../images/missing_headshot.jpeg'
import AthleteProfile from '../profile/AthleteProfile'

import Modal from 'react-modal'
import { useState } from 'react'
import { getSalary } from './FantasyHelpers'

const TeamTable = ({aths, scores, totalPoints}) => {  
  // sort athletes by high score
  aths.sort((a, b) => totalPoints[b.id] - totalPoints[a.id])

  const [modalAth, setModalAth] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = (ath) => {
    setModalAth(ath)
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  const score = (ath_id, stage) => {
    // verify scores is not null
    if (scores) {
      if (stage === 't') {
        colTotals[stage] += totalPoints[ath_id]
        return totalPoints[ath_id]
      }
      if (!(ath_id in scores)) return 0 
      const stages = scores[ath_id].scores
      const retval = stage in stages ? stages[stage] : 0
      colTotals[stage] += retval
      return retval
    }
    return 0
  }

  let rowCount = 0
  let colTotals = {q: 0, s: 0, f: 0, t: 0}

  return (
    <table style={{color: 'white'}}>
      <thead style={{background: 'rgb(161, 12, 12)'}}>
        <tr>
          <th></th>
          <th style={{textAlign: 'left', width: '10vw'}}>Name</th>
          <th style={{width: '4vw'}}>Salary</th>
          <th>Q</th>
          <th>S</th>
          <th>F</th>
          <th>Tot</th>
        </tr>
      </thead>
      <tbody>
        {aths.map(ath => 
          <tr className='draft-table-row'
              onClick={() => openModal(ath)}
              style={{ background: rowCount++ % 2 === 0 ? 'rgb(13, 13, 51)' : 'rgb(35, 35, 92)'}}>
            <td style={{borderTopLeftRadius: '0.5vw', 
                        borderBottomLeftRadius: '0.5vw',
                      }}>
              <img className='profile-headshot-small' src={ath.photo_url ? ath.photo_url : missing_headshot} alt=''/>
            </td>
            <td style={{textAlign: 'left'}}>
              {ath.firstname + ' ' + ath.lastname}
            </td>
            <td style={{color: 'green'}}>
              {getSalary(ath)}
            </td>
            <td>
              {score(ath.id, 'q')}
            </td>
            <td>
              {score(ath.id, 's')}
            </td>
            <td>
              {score(ath.id, 'f')}
            </td>
            <td style={{borderTopRightRadius: '0.5vw', 
                        borderBottomRightRadius: '0.5vw'}}>
              <div style={{padding: '0.2vw'}}>
                {Math.round(score(ath.id, 't') * 100) / 100}
              </div>
            </td>
          </tr>
        )}
        <tr className='draft-table-row'
            style={{ background: rowCount++ % 2 === 0 ? 'rgb(13, 13, 51)' : 'rgb(35, 35, 92)'}}>
          <td style={{borderTopLeftRadius: '0.5vw', 
                      borderBottomLeftRadius: '0.5vw',
                    }}>
          </td>
          <td style={{textAlign: 'left'}}>
            Total
          </td>
          <td>
          </td>
          <td>
            {colTotals.q.toFixed(2)}
          </td>
          <td>
            {colTotals.s.toFixed(2)}
          </td>
          <td>
            {colTotals.f.toFixed(2)}
          </td>
          <td style={{borderTopRightRadius: '0.5vw', 
                      borderBottomRightRadius: '0.5vw'}}>
            <div className='fantasy-final-score'>
              {colTotals.t.toFixed(2)}
            </div>
          </td>
        </tr>
      </tbody>

      <Modal
        className='modal' 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        preventScroll={true}
        appElement={document.body}
      >
        <AthleteProfile athData={modalAth}/>
      </Modal>
    </table>
  )
}

export default TeamTable
