import { Link } from 'react-router-dom'

const DropdownMenu = ({link, sub, color, onClick}) => {

  return (
    <div className="dropdown">
      {sub.length === 0 && <div className='loading'>Loading...</div>}
      {sub.map((item) => 
        <Link key={item} onClick={onClick} to={`/${link}/${item}`}>
          <div className='btn' style={{background: color}}>{item}</div>
        </Link>
      )}
    </div>
  )
}

export default DropdownMenu
