import {useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getCurrentUser, putUser, logout, updatePassword } from './AuthApiCalls'
import { fieldError, fieldErrorPassword } from './AuthHelpers'
import InputUser from './InputUser'

const Account = () => {
  const [defUsername, setDefUsername] = useState('')
  const [defEmail, setDefEmail] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')

  // success indicator and message of signup request
  const [success, setSuccess] = useState(null)
  const [errors, setErrors] = useState({})

  const [save, setSave] = useState('Save')
  
  const [curPass, setCurPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [newPassConf, setNewPassConf] = useState('')

  // success indicator and errors for password update
  const [successPass, setSuccessPass] = useState(null)
  const [errorsPass, setErrorsPass] = useState('')

  const [update, setUpdate] = useState('Update Password')

  // toggle the password change dropdown
  const [passOpen, setPassOpen] = useState(false)
  const onChangePass = () => setPassOpen(!passOpen)
  
  const nav = useNavigate()
  useEffect(() => {
    // GET the current user
    const getUser = async () => {
      const [status, response_json] = await getCurrentUser()
      // set the current username and email if response_json is not null
      if (status === 200) {
        setUsername(response_json.username)
        setDefUsername(response_json.username)
        setEmail(response_json.email)
        setDefEmail(response_json.email)
      } else {
        // no user logged in, redirect to login page
        nav('/fantasy/login')
      }
    }
    getUser()
  }, [nav])

  // PUT request - update the current user's info
  const updateUser = async () => {
    const body = {email: email, username: username}
    const [status, response_json] = await putUser(body)

    if (status === 200) {
      setSuccess(true)
      setSave('Saved!')
      setDefUsername(username)
      setDefEmail(email)
    } else {
      // set errors
      'errors' in response_json && setErrors(response_json.errors)
    }
  }

  // PUT request - update the current user's info
  const updateUserPassword = async () => {
    const body = {current: curPass, new: newPass, new_confirm: newPassConf}
    const [status, response_json] = await updatePassword(body)

    if (status === 200) {
      setSuccessPass(true)
      setUpdate('Password Updated!')
    } else {
      setSuccessPass(false)
      // set errors
      'errors' in response_json && setErrorsPass(response_json.errors)
    }
  }

  return (
    <div className='grow-vert-elo'>
      <div className='auth-body-container'>
        <h1 style={{background: 'none', marginBottom: '0px'}}>Hello, {defUsername}!</h1>
        <div className='grow-vert-elo'>
          <div>You can update your account settings here.</div>
          <div>Never give out your credentials to anyone!</div>
        </div>
        <table><tbody>
          <tr>
            <td>Username</td>
            <td>Email</td>
          </tr>
          <tr>
            <td>
              <InputUser placeholder='Username' defaultValue={defUsername} onChange={event => {
                      setUsername(event.target.value)
                      setSave('Save')
                    }}/>
            </td>
            <td>
              <InputUser placeholder='Email' defaultValue={defEmail} onChange={event => {
                      setEmail(event.target.value)
                      setSave('Save')
                    }}/>
            </td>
          </tr>
          <tr>
            <td className='error-message'>{fieldError(success, errors, 'username')}</td>
            <td className='error-message'>{fieldError(success, errors, 'email')}</td>
          </tr>
        </tbody></table>
        <button className='btn' style={{background: 'green'}} onClick={updateUser}>{save}</button>

        <div className='btn' onClick={onChangePass}>Change Password</div>
        {passOpen && 
        <div className='grow-vert-elo' style={{gap: '25px'}}>
        <table><tbody>
          <tr>
            <td>Currrent Password</td>
            <td>New Password</td>
            <td>New Password Again</td>
          </tr>
          <tr>
            <td>
              <InputUser placeholder='Current Password' onChange={event => {
                      setCurPass(event.target.value)
                      setUpdate('Update Password')
                    }}/>
            </td>
            <td>
              <InputUser placeholder='New Password' onChange={event => {
                      setNewPass(event.target.value)
                      setUpdate('Update Password')
                    }}/>
            </td>
            <td>
              <InputUser placeholder='New Password Again' onChange={event => {
                      setNewPassConf(event.target.value)
                      setUpdate('Update Password')
                    }}/>
            </td>
          </tr>
          <tr>
            <td className='error-message'>{fieldErrorPassword(successPass, errorsPass, 'current')}</td>
            <td className='error-message'>{fieldErrorPassword(successPass, errorsPass, 'new')}</td>
            <td className='error-message'>{fieldErrorPassword(successPass, errorsPass, 'new_confirm')}</td>
          </tr>
        </tbody></table>
        <button className='btn' style={{background: 'green'}} onClick={updateUserPassword}>{update}</button>
        </div>
        }
        <Link to='/fantasy/login'
              onClick={logout}
              style={{color: 'red', textDecoration: 'underline'}}>Logout</Link>
      </div>
    </div>
  )
}

export default Account
