import React, { useEffect, useState } from 'react'
import DonutChart from 'react-donut-chart'

const Insights = ({ roundData }) => {
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    const routes = []

    if ('routes' in roundData) {
      for (const route of roundData.routes) {
        route.name = `Route ${route.name}`
        routes.push(route)
      }
    } else if ('starting_groups' in roundData) {
      for (const group of roundData.starting_groups) {
        for (const route of group.routes) {
          route.name = `${group.name} - Route ${route.name}`
          routes.push(route)
        }
      }
    }
    setRoutes(routes)
  }, [roundData])

  return (
    <div>
      <div>
        <div className="grow-horiz">
          <div>
            <h1>Round Insights</h1>
            <div className="inline-legend">
              <span className="dot-rating-little"></span>
              <span>Round difficulty out of 10 </span>
            </div>
            <div className="inline-legend">
              <span className="dot-top-little"></span>
              <span>Tops</span>
            </div>
            <div className="inline-legend">
              <span className="dot-zone-little"></span>
              <span>Average Score</span>
            </div>
          </div>
          <span className="dot-rating">{myRound(roundData.grade, 1)}</span>
        </div>
        {routes.map((route) => (
          <div className="route-background">
            <div>
              <h2>{route.name}</h2>
              <div className="inline">
                <DonutChart
                  data={[
                    { label: 'Top Rate', value: route.tops },
                    {
                      label: 'No Top Rate',
                      value: route.attempts - route.tops,
                    },
                  ]}
                  height={125}
                  width={125}
                  legend={false}
                  formatValues={(values, total) =>
                    !total ? '--' : `${((values / total) * 100).toFixed(0)}%`
                  }
                />
                <span className="dot-top">
                  {route.tops ? route.tops : '--'}
                </span>
                <span className="dot-zone">
                  {route.average_score ? myRound(route.average_score, 1) : '--'}
                </span>
              </div>
            </div>
            {route.crux_moves && (
              <div>
                <h2>
                  Crux Moves:{' '}
                  {Object.keys(route.crux_moves).length === 0 && 'None'}
                </h2>
                <div className="inline">
                  {Object.keys(route.crux_moves)
                    .sort()
                    .map((move) => (
                      <span
                        className="dot-stop"
                        style={{
                          background: `rgba(255, 0, 0, ${
                            0.3 +
                            (1.7 * route.crux_moves[move]) /
                              Math.min(40, route.attempts)
                          })`,
                          borderColor: `rgba(0, 0, 0, ${Math.floor(
                            route.crux_moves[move] / 10
                          )})`,
                        }}
                      >
                        <p>{move}</p>
                        <p style={{ fontSize: '1.1vw' }}>
                          ({route.crux_moves[move]})
                        </p>
                      </span>
                    ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

// round x to places number of decimal places
const myRound = (x, places = 0) =>
  Math.round(x * Math.pow(10, places)) / Math.pow(10, places)

export default Insights
