import Modal from "react-modal"

const ModalResults = ({modalIsOpen, closeModal}) => {
  return (
    <Modal
      className='modal-rules' 
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      preventScroll={true}
      appElement={document.body}
    >
      <div className='grow-vert-container' style={{width: '35vw'}}>
        <h2 style={{marginTop: 0}}>Rules</h2>
        <ul style={{listStyle: 'inherit', marginLeft: '1vw', marginRight: 'auto'}}>
          <li>
            View and edit your team for this event
          </li>
          <li>
            Once an event begins, you will be unable to edit your team
          </li>
          <li>
            Click on a leaderboard team to view the team details
          </li>
          <li>
            Click Event Results to see the full results from the event
          </li>
        </ul>
      </div>
      <div className='grow-vert-container' style={{width: '35vw'}}>
        <h2 style={{marginTop: 0}}>Scoring</h2>
        <ul style={{listStyle: 'inherit', marginLeft: '1vw', marginRight: 'auto'}}>
          <li>
            Each athlete can score a maximum of 30 points per round of the competition
          </li>
          <li>
            A round score is determined by the athlete's rank in that round
          </li>
          <li>
            Score more points to finish higher on the leaderboard
          </li>
        </ul>
      </div>
    </Modal>
  )
}

export default ModalResults
