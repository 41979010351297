import { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTrophy } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal'
import TeamTable from "./TeamTable"

const LeaderboardTable = ({teams, scores, totalPoints, eventAths}) => {
  const [modalTeam, setModalTeam] = useState({})
  const [modalAths, setModalAths] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = (team) => {
    setModalTeam(team)
    const aths = eventAths.filter(ath => team.athletes.includes(ath.id))
    setModalAths(aths)
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  const setRank = (team, rank) => {
    team.rank = rank
    return rank
  }

  let rowCount = 0
  
  return (
    <div>
      <table style={{color: 'white'}}>
        <thead style={{background: 'green'}}>
          <tr>
            <th>Rank</th>
            <th style={{textAlign: 'left', width: '25vw'}}>Username</th>
            <th>Score</th>
          </tr>
        </thead>

        <tbody>
          {teams.map(team => 
            <tr className='draft-table-row'
                style={{background: rowCount++ % 2 === 0 ? 'rgb(13, 13, 51)' : 'rgb(35, 35, 92)'}}
                onClick={() => openModal(team)}>
              <td style={{borderTopLeftRadius: '0.5vw', 
                          borderBottomLeftRadius: '0.5vw',
                        }}>
                {setRank(team, rowCount)}
              </td>
              <td style={{textAlign: 'left'}}>
                {team.username}
              </td>
              <td style={{borderTopRightRadius: '0.5vw', 
                          borderBottomRightRadius: '0.5vw'}}>
                <div style={{ borderRadius: '0.5vw', padding: '0.2vw'}}>
                  {team.points ? team.points.toFixed(2) : '-'}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Modal
        className='modal-team' 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        preventScroll={true}
        appElement={document.body}
      >
        <h2 style={{margin: 0, marginLeft: '3vw', marginRight: 'auto'}}>
          <FontAwesomeIcon icon={faUser} style={{marginRight: '0.5vw'}}/>
          {modalTeam.username}
        </h2>
        <h3 style={{marginTop: 0, marginLeft: '3vw', marginRight: 'auto'}}>
          <FontAwesomeIcon icon={faTrophy} style={{marginRight: '0.5vw', color: 'gold'}}/>
          {modalTeam.rank} of {teams.length}
        </h3>
        <TeamTable aths={modalAths}
                   scores={scores}
                   totalPoints={totalPoints}/>
      </Modal>
    </div>
  )
}

export default LeaderboardTable
