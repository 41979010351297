import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import Countdown from 'react-countdown'
import { getCurrentUserTeams, getEventsTeamCount } from './TeamApiCalls'

import { faUser, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getCurrentUser } from '../auth/AuthApiCalls'

const FantasyEvents = ({ events }) => {

  // array of pairs of current fantasy event ids
  // [[m, w], [m, w], ...]
  const [curFids, setCurFids] = useState([])
  const [pastFids, setPastFids] = useState([])
  const [names, setNames] = useState({})

  useEffect(() => {
    // returns whether or not the given time is in the future
    const active = (endDate) => {
      const date = new Date(endDate)
      date.setDate(date.getDate() + 1)
      return date > new Date()
    }
    // current is a boolean indicating whether or not we
    // are setting fids for current or past events
    const setFids = (current) => {
      let filtered = []
      if (current) {
        filtered = events.filter((e) => active(e.end_date))
      } else {
        filtered = events.filter((e) => !active(e.end_date))
      }
      filtered.sort((a, b) => {
        if (a.event_id !== b.event_id) {
          return b.event_id - a.event_id
        }
        return a.discipline < b.discipline ? -1 : 1
      })

      const fids = filtered.map((event) => event.fantasy_event_id)
      let pairs = []
      for (let i = 0; i < fids.length; i += 2) {
        if (fids[i] < fids[i + 1]) {
          pairs.push([fids[i], fids[i + 1]])
        } else {
          pairs.push([fids[i + 1], fids[i]])
        }
      }
      current ? setCurFids(pairs) : setPastFids(pairs)
    }
    if (events.length > 0) {
      // verify currentEvents has been delivered
      setNames(getEventNames(events))
      setFids(true)
      setFids(false)
    }
  }, [events])

  const [numTeams, setNumTeams] = useState({})
  // get a mapping for the number of fantasy teams for each event
  useEffect(() => {
    const getTeams = async () => {
      const [status, teams] = await getEventsTeamCount()
      if (status === 200) {
        setNumTeams(teams)
      }
    }
    getTeams()
  }, [])

  const [myTeams, setMyTeams] = useState([])

  // get all the teams of the current user
  useEffect(() => {
    const getMyTeams = async () => {
      const [status, teams] = await getCurrentUserTeams()
      if (status === 200) {
        setMyTeams(teams)
      }
    }
    getMyTeams()
  }, [])

  // check if there is a user logged in
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    const getUser = async () => {
      const [status, responseJson] = await getCurrentUser()
      console.log(responseJson.length)
      if (status === 200) {
        setLoggedIn(true)
      }
    }
    getUser()
  }, [])

  // determine what path to link to based on whether
  // or not the current user has already made a team
  // for the given event
  const getPath = (fid) => {
    if (!loggedIn) return '/fantasy/login'
    const eventTeams = myTeams.filter((team) => team.fantasy_event_id === fid)
    return eventTeams.length > 0 ? `${fid}/results` : `${fid}/build`
  }

  const joined = (fid) =>
    myTeams.filter((team) => team.fantasy_event_id === fid).length > 0

  const getNumTeams = (fid) => (fid in numTeams ? numTeams[fid] : 0)

  return (
    <div className="grow-vert-elo">
      <div className="fantasy-events-body">
        <div className="grow-horiz-elo">
          {/* <div className='grow-vert-container' style={{width: '30vw'}}>
            <h2 style={{marginTop: 0}}>Current</h2>
            <div>The 2022 IFSC World Cup Season begins in April.</div>
            <div>Learn the rules by mock-drafting a past event!</div>
            <h2>
              <Countdown date='4/1/2022'/>
            </h2>
            <h3 style={{margin: 0}}>Countdown to Moscow Boulder World Cup 2022</h3>
          </div> */}

          <div className="grow-vert-container">
            <h2 style={{ marginTop: 0 }}>Current</h2>
            {curFids.map((pair) => (
              <div className="fantasy-event-card">
                <h3 className="fantasy-event-card-title">
                  <div>
                    {names[pair[0]]} {pair.includes(4740) && '(Mock Draft)'}
                  </div>
                  <div className="discipline-icon">B</div>
                </h3>
                <div className="grow-horiz-fantasy-card">
                  <Link
                    className="fantasy-event-card-men"
                    to={getPath(pair[0])}
                  >
                    <h2>Men</h2>
                    <div>
                      {joined(pair[0]) ? (
                        <FontAwesomeIcon
                          icon={faUserCheck}
                          color="greenyellow"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faUser} />
                      )}
                      <span style={{ marginLeft: '0.5vw' }}>
                        {getNumTeams(pair[0])} Participants
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="fantasy-event-card-women"
                    to={getPath(pair[1])}
                  >
                    <h2>Women</h2>
                    <div>
                      {joined(pair[1]) ? (
                        <FontAwesomeIcon
                          icon={faUserCheck}
                          color="greenyellow"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faUser} />
                      )}
                      <span style={{ marginLeft: '0.5vw' }}>
                        {getNumTeams(pair[1])} Participants
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="grow-vert-container">
            <h2 style={{ marginTop: 0 }}>Past</h2>
            {pastFids.map((pair) => (
              <div className="fantasy-event-card">
                <h3 className="fantasy-event-card-title">
                  <div>
                    {names[pair[0]]} {pair.includes(4740) && '(Mock Draft)'}
                  </div>
                  <div className="discipline-icon">B</div>
                </h3>
                <div className="grow-horiz-fantasy-card">
                  <Link
                    className="fantasy-event-card-men"
                    to={getPath(pair[0])}
                  >
                    <h2>Men</h2>
                    <div>
                      {joined(pair[0]) ? (
                        <FontAwesomeIcon
                          icon={faUserCheck}
                          color="greenyellow"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faUser} />
                      )}
                      <span style={{ marginLeft: '0.5vw' }}>
                        {getNumTeams(pair[0])} Participants
                      </span>
                    </div>
                  </Link>
                  <Link
                    className="fantasy-event-card-women"
                    to={getPath(pair[1])}
                  >
                    <h2>Women</h2>
                    <div>
                      {joined(pair[1]) ? (
                        <FontAwesomeIcon
                          icon={faUserCheck}
                          color="greenyellow"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faUser} />
                      )}
                      <span style={{ marginLeft: '0.5vw' }}>
                        {getNumTeams(pair[1])} Participants
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

// function for getting a mapping from fid to event name
const getEventNames = (data) => {
  let events = {}
  data = data.filter((round) => round.gender.startsWith('m'))
  data.forEach((round) => (events[round.fantasy_event_id] = round.event_name))

  let occurrences = {}
  for (const id in events) {
    let start = events[id].lastIndexOf('-')
    while (events[id].charAt(start + 1) === ' ') {
      start++
    }
    start++
    const loc = events[id].substring(start).trim()
    events[id] = loc
    loc in occurrences ? occurrences[loc]++ : (occurrences[loc] = 0)
  }

  // account for duplicate event locations (e.g. SLC 2021)
  for (const id in events) {
    if (occurrences[events[id]] > 0) {
      const name = events[id]
      events[id] += ' ' + occurrences[events[id]]
      occurrences[name]++
    }
  }
  return events
}

export default FantasyEvents
