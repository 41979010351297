const base = process.env.REACT_APP_API_URL

const exceptionHandler = (e) => {
  console.log(e)
  return [404, {}]
}

// GET all the fantasy_events in the database
export async function getFantasyEvents() {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(`${base}fantasyevents`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET the athletes from a fantasy event
export async function getEventAthletes(fid) {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(
      `${base}fantasyevents/${fid}/athletes`,
      options
    )
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET the scores from a fantasy event
export async function getEventScores(fid) {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(`${base}fantasyevents/${fid}/scores`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET the rounds from a fantasy event
export async function getEventRounds(fid) {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(`${base}fantasyevents/${fid}/rounds`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET all the rounds in the database
export async function getAllRounds() {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(`${base}rounds`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET the rounds of a specific year in the database
export async function getYearRounds(year) {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(`${base}${year}/rounds`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET a single round by round_id
export async function getRound(round_id) {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(`${base}rounds/${round_id}`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET all athletes (just the ids and genders)
export async function getAthletes() {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(`${base}athletes`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET a single athlete by id
export async function getAthlete(athlete_id) {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(`${base}athletes/${athlete_id}`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET the athlete data for the leaders pages
export async function getLeaders(gender) {
  const options = {
    credentials: 'include',
  }
  try {
    const response = await fetch(`${base}athletes/leaders/${gender}`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}
