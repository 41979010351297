import React from 'react'

const SeasonDropdown = ({data, close, setSeason}) => {

  const onClick = (season) => {
    setSeason(season)
    close()
  }

  return (
    <div className='season-dropdown'>
      {data.map(season =>
        <div className='search-drop-item' onClick={e => onClick(season)}>{season}</div>
      )}
    </div>
  )
}

export default SeasonDropdown
