import React from 'react'

const AthletesTableHead = ({color}) => {
  return (
    <thead style={{background: color, }}>
      <tr >
        <th ></th>
        <th style={{textAlign: 'left', width: '16vw'}}>Name</th>
        <th>Current</th>
        <th>Peak</th>
        <th>Salary</th>
        <th style={{width: '2vw'}}>
        </th>
      </tr>
    </thead>
  )
}

export default AthletesTableHead
