import { Link } from "react-router-dom"

const AthleteScoreFinalL = ({ athlete, count }) => {
  // function for calculating the background color of the scorecard based on rank
  const color = (rank) => {
    if (rank !== null && rank === 1) return 'rgb(177, 160, 13)'
    else if (rank !== null && rank === 2) return 'silver'
    else if (rank !== null && rank === 3) return 'rgb(178, 81, 30)'
    return count % 2 === 1 ? 'steelblue' : 'rgba(70, 131, 180, 0.7)'
  }

  return (
    <div className='athlete-score' style={{ background: color(athlete.rank) }}>
      <h1>{athlete.rank}</h1>
      <div className='grow-vert'>
        <h3 style={{ marginBottom: 0, maxWidth: '16vw' }}>
          <Link className='ath-link-text' to={`/athletes/${athlete.athlete_id}`}>{athlete.firstname} {athlete.lastname}</Link>
        </h3>
        ({athlete.country})
      </div>
      <div className='grow-horiz'>
        {athlete.ascents.map(route =>
          <div className='grow-vert'>
            <span className='dot-att-lead'>{route.score}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default AthleteScoreFinalL
