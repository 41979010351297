import { useState, useEffect, useRef } from "react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SearchDropdown from "./SearchDropdown"

const Search = ({aths}) => {

  const [query, setQuery] = useState('')

  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const stayOpen = () => setOpen(true)

  const resultsRef = useRef()

  useEffect(() => {
    const handler = (event) => {
      if (!resultsRef.current.contains(event.target)) setOpen(false)
    }

    document.addEventListener('mousedown', handler)

    // clean up event listener
    return () => document.removeEventListener('mousedown', handler)
  })

  // search results to display
  const results = aths.filter(ath => 
    `${ath.firstname.toLowerCase()} ${ath.lastname.toLowerCase()}`.includes(query.toLowerCase()))
    .slice(0, 5)

  return (
    <div className='nav-item' ref={resultsRef}>
      {<FontAwesomeIcon icon={faSearch} 
        style={{position: 'relative', left:'3vw', top:'2px', width: '1.2vw'}}/>}
      <input className='search-bar' 
        placeholder='Search Athlete'
        onChange={event => setQuery(event.target.value)}
        onClick={stayOpen}
      />
    
      {query.length > 0 && open && <SearchDropdown data={results} onClick={toggleOpen}/>}
    </div>
  )
}

export default Search
