import Countdown from 'react-countdown'

const FantasyHome = () => {
  return (
    <div className='grow-vert-elo'>
      <div className='fantasy-body'>
        <div className='grow-horiz-elo'>
          <div className='grow-vert-tomoa'>
            <h1>
              <Countdown date='4/1/2022'/>
            </h1>
            <h3 style={{margin: 0}}>Countdown to the 2022</h3>
            <h3 style={{margin: 0}}>IFSC World Cup Season</h3>
          </div>
          <div className='grow-vert-container'>
            <h1>Welcome to Fantasy Climbing!</h1>
            <div>Love climbing? Love fantasy sports? You're in the right place!</div>
            <div>Draft your team of athletes for each IFSC Boulder/Lead event and battle others for glory!</div>

            <h2>1) Create an account</h2>
            <div>Don't have an account yet? Click the "My Account" Tab above to create one or login.</div>

            <h2>2) Explore Events</h2>
            <div>Click the "Events" tab to explore events and practice drafting.</div>

            <h2>3) Share With Friends</h2>
            <div>Compete with friends and others in the same events for bragging rights!</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FantasyHome
