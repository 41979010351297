import { Line } from "react-chartjs-2"
import { Link } from "react-router-dom"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import SeasonDropdown from "./SeasonDropdown"

const ProfileDiscipline = ({athData, discipline}) => {
  const [season, setSeason] = useState('All Seasons')
  const toggleSeason = (season) => setSeason(season)

  const [open, setOpen] = useState(false)
  const openMenu = () => setOpen(true)
  const closeMenu = () => setOpen(false)

  // get all the seasons the athlete has been a part of
  let seasons = ['All Seasons']
  athData.all_results.forEach(event => {
    if (!seasons.includes(event.season)) seasons.push(event.season)
  })

  // filter results down to specified season
  let seasonResults = athData.all_results
    .filter(event => event.discipline === discipline && event.rank !== null)
  if (season !== 'All Seasons') {
    seasonResults = seasonResults
      .filter(event => event.season === season)
  }

  const seasonEventIds = seasonResults.map(event => event.event_id)

  // calculate podiums based on season
  const podium = (rank) => seasonResults
    .filter(event => event.rank === rank).length
  const podiumPercent = (rank) => 
    Math.round(100 * seasonResults
      .filter(event => event.rank <= rank).length / seasonResults.length)

  // calculate finals and semis rate
  let id_counts = {}
  athData[`elo_events_${discipline}`].forEach(event_id => {
    if (seasonEventIds.includes(event_id)) {
      if (!(event_id in id_counts)) {
        id_counts[event_id] = 0
      }
      id_counts[event_id]++
    }
  })

  let finals_count = 0
  let semis_count = 0
  for (const id in id_counts) {
    if (id_counts[id] >= 2) {
      semis_count++
      if (id_counts[id] >= 3) {
        finals_count++
      }
    }
  }

  // filter elo data points based on season
  // traverse array backwards because need to add one additional point each time
  let eloData = athData[`elo_${discipline}`]
  if (season !== 'All Seasons') {
    let found = false 
    eloData = []
    for (let i = athData[`elo_${discipline}`].length - 1; i >= 0; i--) {
      if (seasonEventIds.includes(athData[`elo_events_${discipline}`][i])) {
        found = true
        eloData.push(athData[`elo_${discipline}`][i])
      } else if (found) {
        // add the one additional data point from previous season
        eloData.push(athData[`elo_${discipline}`][i])
        break
      }
    }
    eloData = eloData.reverse()
  }
  
  return (
    <div className='discipline-body-container'>
      <h1 style={{textDecorationLine:'underline', margin: '0px' }}>
        {discipline === 'boulder' ? 'Boulder' : 'Lead'}</h1>
      <div className='grow-horiz-space'>
        <div className='grow-vert-center'>
          <div className={`${discipline}-elo-card`}>
            Current Elo: {Math.round(athData[`elo_cur_${discipline}`])} ({
              `rank_cur_${discipline}` in athData ? athData[`rank_cur_${discipline}`] : '-'
            })
          </div>
          <div className={`${discipline}-elo-card`}>
            Peak Elo: {Math.round(athData[`elo_max_${discipline}`])} ({
              athData[`rank_max_${discipline}`]
            })
          </div>
        </div>
        <div onMouseEnter={openMenu} onMouseLeave={closeMenu}>
          <div className='search-drop-btn'> 
            {season}
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
          {open && <SeasonDropdown data={seasons} close={closeMenu} setSeason={toggleSeason} />}
        </div>
      </div>
      <Line
        height='200'
        data={{
          labels: Array(eloData.length).fill(''),
          datasets: [{
            data: eloData.map(rating => Math.round(rating)),
            label: 'Elo',
            borderColor: 'black',
            backgroundColor: discipline === 'boulder' ? 'rgb(53, 173, 163)' : 'rgb(116, 179, 231)'
          }]
        }}
        options={{
          scales: {
            y: {
              ticks: {
                callback: (value, index, values) => value
              }
            }
          }
        }} 
      />
      <h1 style={{ textDecorationLine: 'underline', margin: '0px' }}>Stats</h1>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0px' }}>
      <h2 style={{ margin: '0px' }}>Podiums</h2>
      <div>(% of Events With At Least Such Rank)</div>
      </div>
      <div className='grow-horiz-elo'>
        <span className='dot-silver'>
          <div>{podium(2)}</div>
          <div style={{ fontSize: '15px' }}>({podiumPercent(2)}%)</div>
        </span>
        <span className='dot-gold'>
          <div>{podium(1)}</div>
          <div style={{ fontSize: '15px' }}>({podiumPercent(1)}%)</div>
        </span>
        <span className='dot-bronze'>
          <div>{podium(3)}</div>
          <div style={{ fontSize: '15px' }}>({podiumPercent(3)}%)</div>
        </span>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0px' }}>
        <h2 style={{ margin: '0px' }}>Progression</h2>
        <div>Finals | Semi-Finals</div>
      </div>
      <div className='grow-horiz-elo'>
        <span className='dot-finals'>
          <div>{finals_count}</div>
          <div style={{ fontSize: '15px' }}>({Math.round(100 * finals_count / seasonResults.length)}%)</div>
        </span>
        <span className='dot-semifinals'>
          <div>{semis_count}</div>
          <div style={{ fontSize: '15px' }}>({Math.round(100 * semis_count / seasonResults.length)}%)</div>
        </span>
      </div>
      
      <h1 style={{ textDecorationLine: 'underline', margin: '0px' }}>Full Results ({seasonResults.length})</h1>
      <div className='grow-vert-results'>
        {seasonResults
          .map(event =>
            <Link className='grow-horiz-result' to={`/events/${event.season}/${event.discipline}/${event.event_id}`}>
              <div className='rank-number' style={{background: rankColor(discipline, event.rank)}}>{event.rank}</div>
              <div style={{marginRight: '10px'}}>{event.event_name}</div>
            </Link>
        )}
      </div>
    </div>
  )
}

// returns a color string based on the rank of an athlete
const rankColor = (discipline, rank) => {
  // podium
  if (rank === 1) return 'rgb(177, 160, 13)'
  else if (rank === 2) return 'silver'
  else if (rank === 3) return 'rgb(178, 81, 30)'
  
  if (discipline === 'boulder') {
    // discipline is boulder
    if (rank <= 6) return 'salmon'
    else if (rank <= 20) return 'pink'
  } else {
    // discipline is lead
    if (rank <= 8) return 'salmon'
    else if (rank <= 26) return 'pink'
  }
  // didn't make semis, return generic color
  return 'gray'
}

export default ProfileDiscipline
