import { useState } from "react"
import { Link } from "react-router-dom"
import { resetPassword } from "./AuthApiCalls"

const ResetPassword = () => {
  // fields needed for password reset
  const [token, setToken] = useState('')
  const [email, setEmail] = useState('')
  const [newPass, setNewPass] = useState('')
  const [newPassConfirm, setNewPassConfirm] = useState('')

  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState('')
  // POST request that occurs on click of 'Submit' button
  const onSubmit = async () => {
    const body = {
      token: token,
      email: email, 
      new: newPass,
      new_confirm: newPassConfirm
    }
    const [status, response_json] = await resetPassword(body)
    if (status === 200) {
      setSuccess(true)
      setErrors('')
      console.log(response_json)
    } else if (status === 404) {
      // token or email incorrect
      setErrors('Token or email incorrect.')
    } else if (status === 401) {
      // token has expired
      setErrors('Password reset token has expired.')
    } else {
      // problem with the password
      setErrors('Passwords don\'t match!')
    }
  }

  return (
    <div className='grow-vert-elo'>
      <div className='auth-body-container'>
        <h1 style={{background: 'none', marginBottom: '0px'}}>Reset Your Password</h1>
        <div style={{textAlign: 'center'}}>
          A password-reset code has been sent to your email address. <br/>
          Enter this code here to reset your password.
        </div>
        <input className='form-input' placeholder='Code' onChange={event => setToken(event.target.value)}></input>
        <input className='form-input' placeholder='Email' onChange={event => setEmail(event.target.value)}></input>
        <input 
          className='form-input' 
          // type='password' 
          placeholder='New Password' 
          onChange={event => setNewPass(event.target.value)}/>
        <input 
          className='form-input' 
          // type='password' 
          placeholder='New Password Again' 
          onChange={event => setNewPassConfirm(event.target.value)}/>
        {errors.length > 0 && <div className="error-message">{errors}</div>}
        <input className='btn' type='submit' onClick={onSubmit}/>
        {success && 
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{color: 'green'}}>Your password was successfully reset.</div>
            <Link style={{color: 'blue', textDecoration: 'underline'}} to='/fantasy/login'>Login here</Link>
          </div>
        }
      </div>
    </div>
  )
}

export default ResetPassword
