const base = process.env.REACT_APP_API_URL

const exceptionHandler = (e) => {
  console.log(e)
  return [404, {}]
}

// GET all the users
export async function getUsers() {
  const options = {
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}users`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// POST request for user signup
export async function signup(body) {
  const options = {
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(body),
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}signup`, options)
    const response_json = await response.json()
    // store the jwt token in local storage
    if (response.status === 201) {
      localStorage.setItem('jwt', response_json.auth_token)
    }
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// POST request that occurs on click of 'Submit' button
export async function login(body) {
  const options = {
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(body),
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}login`, options)
    const response_json = await response.json()
    // store the jwt token in local storage
    if (response.status === 200) {
      localStorage.setItem('jwt', response_json.auth_token)
    }
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET the current user
// status always 200; response_json is null if no current user
export async function getCurrentUser() {
  const options = {
    credentials: 'include',
    headers: {'Authorization': localStorage.getItem('jwt')}
  }
  try {
    const response = await fetch(`${base}users/current`, options)
    const response_json = await response.json()
    // console.log(response_json)
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// Update the current user's info
export async function putUser(body) {
  const options = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('jwt'),
    },
    body: JSON.stringify(body),
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}users/current`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// Update the user's password
export async function updatePassword(body) {
  const options = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('jwt'),
    },
    body: JSON.stringify(body),
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}users/current/password`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// Log out the current user
export async function logout() {
  localStorage.setItem('jwt', null)
}

// Reset the password for a user who forgot
export async function resetPassword(body) {
  const options = {
    method: 'PUT',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(body),
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}resetpassword`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

export async function forgotPassword(body) {
  const options = {
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(body),
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}resetpassword`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}