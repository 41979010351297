import React from 'react'

const InputUser = ({placeholder, defaultValue, onChange}) => {

  return (
    <input 
      type={placeholder.includes('Password') ? 'password' : ''}
      className='form-input' 
      placeholder={placeholder} 
      defaultValue={defaultValue} 
      onChange={onChange}/>
  )
}

export default InputUser
