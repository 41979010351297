import { Link } from 'react-router-dom'

const SearchDropdown = ({ data, onClick }) => {

  return (
    <div className="search-dropdown">
      {data.length === 0 ? 
        <div style={{color:'white'}}>No Matches</div> : 
        data.map(ath =>
        <Link onClick={onClick} to={`/athletes/${ath.id}`}>
          <div className='search-bar-btn'>{ath.firstname} {ath.lastname}</div>
        </Link>
      )}
    </div>
  )
}

export default SearchDropdown
