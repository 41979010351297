
const Button = ({className, text, color, onClick}) => {

  return (
    <button
      className={className}
      style={{background: color}}
      onClick={onClick}
    >
      {text}
    </button>
  )
}


export default Button
