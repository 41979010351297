import missing_headshot from '../../images/missing_headshot.jpeg'

const ProfileHead = ({athData}) => {
  // calculate age of athlete as birthday field in athData is static
  const birthdate = new Date(athData.birthday)
  const diff = Date.now() - birthdate.getTime()
  const ageDate = new Date(diff)
  const age = Math.abs(ageDate.getUTCFullYear() - 1970)

  return (
    <div className='profile-head'>
      <img className='profile-headshot' src={athData.photo_url ? athData.photo_url : missing_headshot} alt='' />
      <div style={{ maxWidth: '20vw' }}>
        <h1>{athData.firstname} {athData.lastname}</h1>
        <div>Age: {age}</div>
        <div>Height: {athData.height === null ? '--' : athData.height + 'cm | ' + heightInFeet(athData.height)}</div>
        <div>Country: {athData.country}</div>
        <img className='profile-flag' src={athData.flag_url} alt='' />
        
      </div>
      <div className='grow-vert-elo'>
        <div style={{ fontSize: '25px', fontWeight: '700' }}>Elo Rating</div>
        <div>(Rank)</div>
        <div className='grow-horiz-elo'>
          <div className='grow-vert-elo'>
            {'elo_boulder' in athData ? 
            <span className='dot-elo-b'>
              <div>{Math.round(athData.elo_cur_boulder)}</div>
              <div style={{ fontSize: '15px' }}>({
                'rank_cur_boulder' in athData ? athData.rank_cur_boulder : '-'
              })</div>
              </span> : <span className='dot-elo-b'>--</span>}
            <div className='grow-horiz'>
              <span className='dot-zone-little'></span>
              Boulder
            </div>
          </div>
          <div className='grow-vert-elo'>
            {'elo_lead' in athData ?
            <span className='dot-elo-l'>
              <div>{Math.round(athData.elo_cur_lead)}</div>
              <div style={{ fontSize: '15px' }}>({
                  'rank_cur_lead' in athData ? athData.rank_cur_lead : '-'
              })</div>
              </span> : <span className='dot-elo-l'>--</span>}
            <div className='grow-horiz'>
              <span className='dot-top-little'></span>
              Lead
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

const heightInFeet = (cm) => {
  const totInches = Math.round(cm / 2.54)
  const feet = Math.floor(totInches / 12)
  const inches = totInches % 12
  return `${feet}'${inches}"`
}

export default ProfileHead
