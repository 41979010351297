import ProfileHead from "./ProfileHead"
import ProfileDiscipline from "./ProfileDiscipline"
import { useEffect, useState } from "react"
import { getAthlete } from "../../ApiCalls"

const AthleteProfile = ({athData}) => {
  const [athFull, setAthFull] = useState(athData)

  useEffect(() => {
    const getAthleteById = async () => {
      const [status, response_json] = await getAthlete(athData.id)
      if (status === 200) {
        response_json.id = response_json.athlete_id
        setAthFull(response_json)
      }
    }
    getAthleteById()
  }, [athData])

  return (
    <div className='profile-container'>
      <ProfileHead athData={athFull}/>
      <div className='grow-horiz-elo'>
        {'elo_boulder' in athFull && <ProfileDiscipline athData={athFull} discipline='boulder'/>}
        {'elo_lead' in athFull && <ProfileDiscipline athData={athFull} discipline='lead'/>}
      </div>
    </div>
  )
}

export default AthleteProfile
