import React from 'react'
import AthleteScoreB from './AthleteScoreB'

const AthleteScoreQuali = ({ athlete, count }) => {
  // function for calculating the background color of the scorecard based on rank
  const color = (rank) => {
    if (rank !== null && rank <= 20 && count < 20) return count % 2 === 0 ? 'palevioletred' : 'rgba(216, 112, 147, 0.7)'
    return count % 2 === 0 ? 'steelblue' : 'rgba(70, 131, 180, 0.7)'
  }

  return (
    <AthleteScoreB athlete={athlete} color={color} />
  )
}

export default AthleteScoreQuali
