// get the event name to display from a fantasy_event object
export function getEventName(event) {
  let name = event.event_name
  let start = name.lastIndexOf('-')
  while (name.charAt(start + 1) === ' ') {
    start++
  }
  start++
  const end = name.indexOf('20') - 1
  name = name.substring(start, end).trim()
  return name
}

// get the date string of an event from a fantasy_event object
export function getEventDate(event) {
  const date = new Date(event.start_date)
  date.setDate(date.getDate() + 1)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()
  return `${month}/${day}/${year}`
}

// compute and return the salary of an athlete
export function getSalary(ath, disc) {
  if (disc.toLowerCase().charAt(0) === 'b') {
    return ath.elo_cur_boulder ? 
      Math.max(0, Math.round((ath.elo_cur_boulder - 1000) / 30)) : 
      0
  }
  return ath.elo_cur_lead ? 
    Math.max(0, Math.round((ath.elo_cur_lead - 1000) / 30)) : 
    0
}