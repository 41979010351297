import React from 'react'
import { Link } from 'react-router-dom'

const AthleteScoreQualiL = ({ athlete, count }) => {
  // function for calculating the background color of the scorecard based on rank
  const color = (rank) => {
    if (rank !== null && rank <= 26 && count < 26) return count % 2 === 0 ? 'palevioletred' : 'rgba(216, 112, 147, 0.7)'
    return count % 2 === 0 ? 'steelblue' : 'rgba(70, 131, 180, 0.7)'
  }

  return (
    <div className='athlete-score' style={{ background: color(athlete.rank) }}>
      <h1>{athlete.rank}</h1>
      <div className='grow-vert'>
        <h3 style={{ marginBottom: 0, maxWidth: '14vw' }}>
          <Link className='ath-link-text' to={`/athletes/${athlete.athlete_id}`}>{athlete.firstname} {athlete.lastname}</Link>
        </h3>
        ({athlete.country})
      </div>
      <h2 style={{color:'black'}}>{athlete.score}</h2>
      <div className='grow-horiz'>
        {athlete.ascents.map(route =>
          <div className='grow-vert'>
            <span className='dot-att-lead'>{route.score}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default AthleteScoreQualiL
