import { Scatter } from "react-chartjs-2"

const EloScatter = ({names, data, color}) => {
  return (
    <Scatter
      height='250'
      data={{
        datasets: [{
          label: 'Elo Ratings vs. Number of Participations',
          data: data,
          borderColor: 'black',
          backgroundColor: color
        }]
      }}
      options={{
        plugins: {
          title: {
            display: true,
            color: 'black',
            text: 'Rating vs. Participations (Current Top 50)',
            font: {
              size: 18
            }
          },
          legend: {
            display: false
          },
          tooltip: {
            usePointStyle: true,
            boxPadding: 2,
            callbacks: {
              label: (context) => {
                let retArr = []
                retArr.push(names[context.parsed.x])
                retArr.push(`Rating: ${context.parsed.x}`)
                retArr.push(`Participations: ${context.parsed.y}`)
                return retArr
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
              callback: (value, index, values) => value
            },
            title: {
              display: true,
              text: 'Elo Rating',
              color: 'black'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Number of Participations',
              color: 'black'
            }
          }
        }
      }}
    />
  )
}

export default EloScatter
