import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom'

import YearWrapper from './components/YearWrapper'
import Navbar from './components/nav/Navbar'
import NavItem from './components/nav/NavItem'
import Year from './components/Year'
import Event from './components/Event'
import Round from './components/Round'
import Home from './components/Home'
import AthleteProfile from './components/profile/AthleteProfile'
import Search from './components/search/Search'
import Leaders from './components/leaders/Leaders'
import LeaderStats from './components/leaders/LeaderStats'
import Signup from './components/auth/Signup'
import Login from './components/auth/Login'
import Fantasy from './components/fantasy/Fantasy'
import FantasyHome from './components/fantasy/FantasyHome'
import FantasyEvents from './components/fantasy/FantasyEvents'
import FantasyBuild from './components/fantasy/FantasyBuild'
import Account from './components/auth/Account'
import ScrollToTop from './components/ScrollToTop'
import EventHome from './components/EventHome'
import FantasyResults from './components/fantasy/FantasyResults'
import { getAllRounds, getAthletes, getFantasyEvents } from './ApiCalls'
import ResetPassword from './components/auth/ResetPassword'
import ForgotPassword from './components/auth/ForgotPassword'

const App = () => {
  const [dataRounds, setDataRounds] = useState([])
  const [dataAths, setDataAths] = useState([])
  const [dataFantasyEvents, setDataFantasyEvents] = useState([])

  // initial data fetch - only the basics
  useEffect(() => {
    const getDataRounds = async () => {
      const [status, response_json] = await getAllRounds()
      if (status === 200) {
        setDataRounds(response_json)
      }
    }
    const getAths = async () => {
      const [status, response_json] = await getAthletes()
      if (status === 200) {
        response_json.forEach((ath) => (ath.id = ath.athlete_id))
        setDataAths(response_json)
      }
    }
    const getAllFantasyEvents = async () => {
      const [status, response_json] = await getFantasyEvents()
      if (status === 200) {
        setDataFantasyEvents(response_json)
      }
    }
    getDataRounds()
    getAths()
    getAllFantasyEvents()
  }, [])

  const dataMen = dataAths.filter((ath) => ath.gender === 'male')
  const dataWomen = dataAths.filter((ath) => ath.gender === 'female')

  // filter a YEAR of data down to a specfic discipline
  const getDisciplineData = (yearData, discipline) =>
    yearData.filter((round) => round.discipline === discipline)

  // get a single year of data
  const getYearData = (year) =>
    dataRounds.filter((round) => round.year === year)

  // for url bug purposes
  const replace = (str) => str.replace(/[ ()]/g, '').replace('ç', 'c')

  // function for filtering a year of data down to a specific event
  // const getEventData = (yearData, event) => yearData.filter(round => round.event_id === parseInt(getEvents(yearData)[event]))

  // helper functions that gets events or event names
  const getEventNames = (year, discipline) =>
    Object.keys(getEvents(getDisciplineData(getYearData(year), discipline)))
  const getEventsMap = (year, discipline) =>
    getEvents(getDisciplineData(getYearData(year), discipline))

  // return an array of round_ids that have the given event_id and discipline
  // array is SORTED in order of mq, wq, ms, ws, mf, wf
  const getEventRounds = (event_id, disc) => {
    event_id = parseInt(event_id)
    const rounds = dataRounds.filter(
      (round) => round.event_id === event_id && round.discipline === disc
    )
    if (dataFantasyEvents.length > 0) {
      rounds.forEach((round) => {
        const f_event = dataFantasyEvents.filter(
          (event) => event.fantasy_event_id === round.fantasy_event_id
        )[0]
        const gender = f_event.gender
        const stage = f_event.rounds.filter(
          (stage) => stage.round_id === round.round_id
        )[0]
        let round_stage = 0
        if (stage.stage.toLowerCase().startsWith('s')) round_stage = 2
        else if (stage.stage.toLowerCase().startsWith('f')) round_stage = 4
        if (gender === 'female') round_stage++
        round.stage = round_stage
      })
    }
    rounds.sort((a, b) => a.stage - b.stage)
    return rounds
  }

  const years = [...new Set(dataRounds.map((round) => round.year))]
  years.sort((a, b) => b - a)

  return (
    <Router>
      <ScrollToTop>
        <div className="container">
          <div style={{ paddingTop: '50px' }}>
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="events">
                {years.map((year) => (
                  <Route path={'' + year} element={<YearWrapper />}>
                    <Route
                      index={true}
                      element={
                        <Year
                          eventsB={getEventsMap(year, 'Boulder')}
                          eventsL={getEventsMap(year, 'Lead')}
                          fantasyEvents={dataFantasyEvents}
                          replace={replace}
                          year={year}
                        />
                      }
                    />

                    {['Boulder', 'Lead'].map((disc) =>
                      getEventNames(year, disc).map((event) => (
                        <Route
                          path={disc + '/' + getEventsMap(year, disc)[event]}
                          element={
                            <Event
                              year={year}
                              discipline={disc}
                              event={event}
                              rounds={getEventRounds(
                                getEventsMap(year, disc)[event],
                                disc
                              )}
                            />
                          }
                        >
                          <Route index={true} element={<EventHome />}></Route>
                          {getEventRounds(
                            getEventsMap(year, disc)[event],
                            disc
                          ).map((round) => (
                            <Route
                              path={'' + round.round_id}
                              element={
                                <Round
                                  roundId={round.round_id}
                                  stage={round.stage}
                                  discipline={disc.charAt(0).toLowerCase()}
                                />
                              }
                            />
                          ))}
                        </Route>
                      ))
                    )}
                  </Route>
                ))}
              </Route>

              <Route path="athletes">
                {dataAths.map((ath) => (
                  <Route
                    path={'' + ath.id}
                    element={<AthleteProfile athData={ath} />}
                  />
                ))}
              </Route>

              <Route path="leaders" element={<Leaders />}>
                <Route
                  path="men"
                  element={<LeaderStats athData={dataMen} gender="male" />}
                ></Route>
                <Route
                  path="women"
                  element={<LeaderStats athData={dataWomen} gender="female" />}
                ></Route>
              </Route>

              <Route path="fantasy" element={<Fantasy />}>
                <Route path="home" element={<FantasyHome />} />
                <Route
                  path="events"
                  element={
                    <FantasyEvents
                      events={dataFantasyEvents.filter((e) => e.display)}
                    />
                  }
                />
                {dataFantasyEvents.map((event) => (
                  <Route
                    path={`events/${event.fantasy_event_id}/build`}
                    element={<FantasyBuild event={event} />}
                  />
                ))}
                {dataFantasyEvents.map((event) => (
                  <Route
                    path={`events/${event.fantasy_event_id}/results`}
                    element={<FantasyResults event={event} />}
                  />
                ))}

                <Route path="account" element={<Account />} />
                <Route path="signup" element={<Signup />} />
                <Route path="login" element={<Login />} />
                <Route path="resetpassword" element={<ResetPassword />} />
                <Route path="forgotpassword" element={<ForgotPassword />} />
              </Route>
            </Routes>
          </div>
          <Navbar>
            <Search aths={dataAths} />
            <NavItem
              text="Leaders"
              sub={['Men', 'Women']}
              color="palevioletred"
            />
            <NavItem text="Events" sub={years} color="steelblue" />
            <li className="nav-item">
              <Link
                className="btn"
                to="fantasy/home"
                style={{ background: 'green' }}
              >
                Fantasy
              </Link>
            </li>
          </Navbar>
        </div>
      </ScrollToTop>
    </Router>
  )
}

// function for getting a mapping of event to event id from the entire dataset
const getEvents = (data) => {
  let events = {}
  data.forEach((round) => (events[round.event_id] = round.event))

  let occurrences = {}
  for (const id in events) {
    let start = events[id].lastIndexOf('-')
    while (events[id].charAt(start + 1) === ' ') {
      start++
    }
    start++
    // TODO: '20' as a prefix for a year is a magic number
    const end = events[id].indexOf('20') - 1
    const loc = events[id].substring(start, end)
    events[id] = loc
    loc in occurrences ? occurrences[loc]++ : (occurrences[loc] = 0)
  }

  // account for duplicate event locations (e.g. SLC 2021)
  for (const id in events) {
    if (occurrences[events[id]] > 0) {
      const name = events[id]
      events[id] += ' ' + occurrences[events[id]]
      occurrences[name]++
    }
  }
  let resultEvents = {}
  for (const id in events) {
    resultEvents[events[id]] = id
  }
  return resultEvents
}

export default App
