import React from 'react'
import { Link } from 'react-router-dom'
import logo_blue from '../../images/compstyle.png'

const Navbar = (props) => {
  return (
    <div style={{ width: '100vw', top: 0, position: 'fixed' }}>
      <nav className="navbar">
        <Link className="title" to="/">
          <img className="ifsc-logo" alt="" src={logo_blue} />
          <span>CompStyle</span>
        </Link>
        <ul className="navbar-nav">{props.children}</ul>
      </nav>
    </div>
  )
}

export default Navbar
