import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

import AthletesTableBody from './AthletesTableBody'
import AthletesTableHead from './AthletesTableHead'
import { createTeam, getMyEventTeam, updateTeam } from './TeamApiCalls'
import { getEventAthletes } from '../../ApiCalls'
import { getEventDate, getEventName, getSalary } from './FantasyHelpers'
import ModalBuild from './ModalBuild'

const FantasyBuild = ({ event }) => {
  const nav = useNavigate()

  const [teamSize, setTeamSize] = useState(6)
  const [available, setAvailable] = useState([])
  const [query, setQuery] = useState('')
  const [team, setTeam] = useState([])
  const [salary, setSalary] = useState(100)

  const [teamIds, setTeamIds] = useState([])
  const [createNew, setCreateNew] = useState(false)

  useEffect(() => {
    // check if we are in edit mode (user has already made a team)
    const getEventTeam = async () => {
      const [status, team] = await getMyEventTeam(event.fantasy_event_id)
      if (status === 200) {
        setTeamIds(team.athletes)
        setCreateNew(false)
      } else {
        setCreateNew(true)
      }
    }
    if (event) {
      event.discipline === 'lead' && setTeamSize(8)
      getEventTeam()
    }
  }, [event])

  // get all the athletes available for this event
  const [athData, setAthData] = useState([])
  useEffect(() => {
    const getAthletes = async () => {
      const [status, response_json] = await getEventAthletes(
        event.fantasy_event_id
      )
      if (status === 200) {
        response_json.map((ath) => (ath.id = ath.athlete_id))
        setAthData(response_json)
      }
    }
    if (event) {
      getAthletes()
    }
  }, [event])

  useEffect(() => {
    let eventAths = athData.slice()
    eventAths.forEach((ath) => (ath.salary = getSalary(ath, event.discipline)))
    eventAths.sort((a, b) => b.salary - a.salary)

    const curTeam = eventAths.filter((ath) => teamIds.includes(ath.id))
    const available = eventAths.filter((ath) => !teamIds.includes(ath.id))

    let salary = 100
    curTeam.forEach((ath) => (salary -= ath.salary))

    setSalary(salary)
    setAvailable(available)
    setTeam(curTeam)
  }, [athData, teamIds, event])

  // add an athlete to the team
  const addAthlete = (athToAdd) => {
    if (team.length < teamSize) {
      setSalary(salary - athToAdd.salary)
      setAvailable(available.filter((ath) => ath.id !== athToAdd.id))
      const newTeam = [...team, athToAdd]
      newTeam.sort((a, b) => b.salary - a.salary)
      setTeam(newTeam)
    }
  }

  const removeAthlete = (athToRemove) => {
    setSalary(salary + athToRemove.salary)
    setTeam(team.filter((ath) => ath.id !== athToRemove.id))
    const newAvailable = [...available, athToRemove]
    newAvailable.sort((a, b) => b.salary - a.salary)
    setAvailable(newAvailable)
  }

  const [error, setError] = useState('')

  // called on attempt to create a team
  // check if salary valid and team is full
  const onCreate = async () => {
    if (team.length !== teamSize) {
      setError('Roster not full yet!')
    } else if (salary < 0) {
      setError('Salary of team must not exceed $100!')
    } else {
      // successful creation
      const teamIds = team.map((ath) => ath.id).toString()
      const body = {
        athletes: `{${teamIds}}`,
        fantasy_event_id: event.fantasy_event_id,
      }

      let status = 200
      let json = {}
      if (createNew) {
        ;[status, json] = await createTeam(body)
      } else {
        ;[status, json] = await updateTeam(body, event.fantasy_event_id)
      }
      if (status === 200) {
        nav('./../results')
      } else {
        // an error happened on the backend, report this error
        setError('' + json)
      }
    }
  }

  // rules + scoring modal
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <div className="grow-vert-elo">
      <div className="fantasy-draft-body">
        <div className="fantasy-build-title">
          <div className="grow-vert" style={{ marginRight: '4vw' }}>
            <h1 style={{ margin: 0 }}>{getEventName(event)}</h1>
            <div className="grow-horiz">
              <div className="discipline-icon-swap">
                {event.discipline.charAt(0).toUpperCase()}
              </div>
              <div className={event.gender === 'male' ? 'm-icon' : 'w-icon'}>
                {event.gender === 'male' ? 'M' : 'W'}
              </div>
              {getEventDate(event)}
            </div>
          </div>
          <h1 style={{ margin: 0 }}>Build Your Team</h1>
          <div
            className="btn"
            onClick={() => openModal()}
            style={{ marginLeft: 'auto' }}
          >
            Rules + Scoring
          </div>
        </div>
        <div className="grow-horiz-events">
          <div className="grow-vert-container">
            <div className="grow-horiz-ends" style={{ gap: '0vw' }}>
              <h2>Available Players</h2>
              {
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{
                    position: 'relative',
                    left: '2.8vw',
                    top: '0.2vh',
                    width: '1.2vw',
                  }}
                />
              }
              <div>
                <input
                  className="search-bar"
                  placeholder="Search Athlete"
                  onChange={(event) => setQuery(event.target.value)}
                />
              </div>
            </div>
            <table style={{ color: 'white' }}>
              <AthletesTableHead color="green" />
              <AthletesTableBody
                aths={available.filter((ath) =>
                  `${ath.firstname.toLowerCase()} ${ath.lastname.toLowerCase()}`.includes(
                    query.toLowerCase()
                  )
                )}
                updateTeam={addAthlete}
                plus={true}
                discipline={event.discipline}
              />
            </table>
          </div>

          <div className="grow-vert-container">
            <div className="grow-horiz-ends" style={{ gap: '10vw' }}>
              <h2>My Team</h2>
              <h3>
                Remaining Salary:
                <span style={{ color: salary >= 0 ? 'green' : 'red' }}>
                  {' '}
                  ${salary}
                </span>
              </h3>
            </div>
            <table style={{ color: 'white' }}>
              <AthletesTableHead color="rgb(161, 12, 12)" />
              <AthletesTableBody
                aths={team}
                updateTeam={removeAthlete}
                plus={false}
                discipline={event.discipline}
              />
            </table>

            <div
              className="btn"
              style={{ marginTop: '5vh' }}
              onClick={() => onCreate()}
            >
              Save My Team
            </div>
            <div className="error-message">{error}</div>
          </div>
        </div>

        <ModalBuild modalIsOpen={modalIsOpen} closeModal={closeModal} />
      </div>
    </div>
  )
}

export default FantasyBuild
