import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { getEventAthletes, getEventScores } from '../../ApiCalls'
import { getEventDate, getEventName } from './FantasyHelpers'

import LeaderboardTable from './LeaderboardTable'
import { getEventTeams, getMyEventTeam } from './TeamApiCalls'
import TeamTable from './TeamTable'
import ModalResults from './ModalResults'

const FantasyResults = ({event}) => {

  // get all the athletes and rounds for this event
  const [athData, setAthData] = useState([])
  const [scores, setScores] = useState(null)
  const [teams, setTeams] = useState([])
  useEffect(() => {
    const getAthletes = async () => {
      const [status, response_json] = await getEventAthletes(event.fantasy_event_id)
      if (status === 200) {
        response_json.map(ath => ath.id = ath.athlete_id)
        setAthData(response_json)
      }
    }
    const getScores = async () => {
      const [status, response_json] = await getEventScores(event.fantasy_event_id)
      if (status === 200) {
        setScores(response_json)
      }
    }
    const getTeams = async () => {
      const [status, teams] = await getEventTeams(event.fantasy_event_id)
      if (status === 200) {
        setTeams(teams)
      }
    }
    if (event) {
      getAthletes()
      getScores()
      getTeams()
    }
  }, [event])

  // const [points, setPoints] = useState([])
  const [totalPoints, setTotalPoints] = useState({})

  // calculate the total points for each athlete
  useEffect(() => {
    let totPoints = {}
    for (const ath_id in scores) {
      totPoints[ath_id] = totalScoreAth(ath_id, scores)
    }
    setTotalPoints(totPoints)
  }, [scores])
  
  const [myTeam, setMyTeam] = useState([])
  const [myTeamId, setMyTeamId] = useState(0)
  useEffect(() => {
    const getMyTeam = async () => {
      let [status, team] = await getMyEventTeam(event.fantasy_event_id)
      if (status === 200) {
        const ath_ids = team.athletes
        setMyTeamId(team.id)
        team = athData.filter(ath => ath_ids.includes(ath.id))
        setMyTeam(team)
      }
    }
    if (event) {
      getMyTeam()
    }
  }, [event, athData])

  // calculate points for each team
  useEffect(() => {
    const copy = teams
    // calculate total points for each team
    copy.forEach(team => {
      const ath_ids = team.athletes
      let teamPoints = 0
      ath_ids.forEach(ath_id => teamPoints += totalPoints[ath_id])
      team.athletes = ath_ids
      team.points = teamPoints
    })
    // sort teams by amount of points
    copy.sort((a, b) => b.points - a.points)
    // add rank to each team
    let rank = 1
    copy.forEach(team => team.rank = rank++)
    setTeams(copy)
  }, [teams, totalPoints])

  // get the username and rank of myTeam
  const [myUsername, setMyUsername] = useState('')
  const [myRank, setMyRank] = useState(0)
  useEffect(() => {
    const team = teams.filter(team => team.id === myTeamId)
    if (team.length === 1) {
      setMyUsername(team[0].username)
      setMyRank(team[0].rank)
    }
  }, [teams, myTeamId])
  

  // rules + scoring modal
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }


  return (
    <div className='grow-vert-elo'>
      <div className='fantasy-draft-body'>
        <div className='fantasy-build-title'>
          <div className='grow-vert' style={{marginRight: '4vw'}}>
            <h1 style={{margin: 0}}>{getEventName(event)}</h1>
            <div className='grow-horiz'>
              <div className='discipline-icon-swap'>{event.discipline.charAt(0).toUpperCase()}</div>
              <div className={event.gender === 'male' ? 'm-icon' : 'w-icon'}>
                {event.gender === 'male' ? 'M' : 'W'}
              </div>
              {getEventDate(event)}
            </div>
          </div>
          <h1 style={{margin: 0}}>Results/Leaderboard</h1>
          <Link className='btn' 
                style={{marginLeft: 'auto'}}
                to={`/events/${event.year}/${event.discipline}/${event.event_id}`}>
            Event Results
          </Link>
          <div className='btn' onClick={() => openModal()}>Rules + Scoring</div>
        </div>
        <div className='grow-horiz-events'>
          <div className='grow-vert-container' style={{background: 'white'}}>
            <div className='grow-horiz-my-team'>
              <h2 style={{margin: 8}}>My Team | {myUsername}</h2>
              <h3 style={{margin: 8}}>
                <FontAwesomeIcon icon={faTrophy} style={{marginRight: '0.5vw'}}/>
                {myRank} of {teams.length}
              </h3>
              <Link to='./../build' style={{color: 'red', textDecoration: 'underline'}}>
                Edit Team
              </Link>
            </div>
            <TeamTable aths={myTeam} 
                      scores={scores}
                      totalPoints={totalPoints}/>
          </div>

          <div className='grow-vert-container' style={{background: 'white'}}>
            <div className='grow-horiz'>
              <h2 style={{margin: 8, marginRight: '10vw'}}>Leaderboard</h2>
              <FontAwesomeIcon icon={faUser}/>
              <span style={{marginLeft: '0.5vw'}}>{teams.length} Participants</span>
            </div>
            <LeaderboardTable teams={teams} 
                              scores={scores} 
                              totalPoints={totalPoints}
                              eventAths={athData}
                              />
          </div>
        </div>

        <ModalResults modalIsOpen={modalIsOpen} closeModal={closeModal}/>
        
      </div>
    </div>
  )
}

// return the total score of an athlete (Q + S + F)
const totalScoreAth = (ath_id, scores) => {
  let total = 0
  if (ath_id in scores) {
    const stages = scores[ath_id].scores
    for (const stage in stages) {
      total += stages[stage]
    }
  }
  return total
}

export default FantasyResults
