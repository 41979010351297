import { Link } from "react-router-dom"

const AthleteScoreB = ({athlete, color}) => {

  const background = (athlete) => immaculate(athlete) ? 'cyan' : 'white'

  // calculate whether an athlete had an immaculate round (Flashed everything)
  const immaculate = (athlete) => 
    athlete.ascents.filter(route => 
      route.top && route.top_tries === 1 && 
      route.zone && route.zone_tries === 1
    ).length === athlete.ascents.length


  return (
    <div className='athlete-score' style={{ background: color(athlete.rank) }}>
      <h1>{athlete.rank}</h1>
      <div className='grow-vert'>
        <h3 style={{ marginBottom: 0, maxWidth: '14vw' }}>
          <Link className='ath-link-text' to={`/athletes/${athlete.athlete_id}`}>{athlete.firstname} {athlete.lastname}</Link>
        </h3>
        ({athlete.country})
      </div>
      <h4>{athlete.score}</h4>
      <div className='grow-horiz'>
        {athlete.ascents.map(route =>
          <div className='grow-vert'>
            <span className='dot-att' 
                  style={{backgroundColor: `${background(athlete)}`}}>
              {route.top ? route.top_tries : "-"}</span>
            <span className='dot-att' 
                  style={{ backgroundColor: `${background(athlete)}` }}>
              {route.zone ? route.zone_tries : "-"}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default AthleteScoreB
