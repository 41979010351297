import AthleteScoreFinalL from "./AthleteScoreFinalL"
import AthleteScoreSemiL from "./AthleteScoreSemiL"
import AthleteScoreQualiL from "./AthleteScoreQualiL"

const ScoresL = ({ roundData, stage }) => {
  let count = 0

  const title = ['Men\'s Qualifiers', 'Women\'s Qualifiers', 'Men\'s Semi-Finals', 
    'Women\'s Semi-Finals', 'Men\'s Finals', 'Women\'s Finals']

  if (!('ranking' in roundData)) {
    return (
      <div>
        <h1>Scoreboard - {title[stage]}</h1>
        <p>Waiting for round to begin...</p>
      </div>
    )
  }

  return (
    <div>
      <h1>Results - {title[stage]}</h1>
      {stage <= 1 && roundData.ranking.map(athleteData =>
        <AthleteScoreQualiL athlete={athleteData} count={count++} />
      )}
      {stage >= 2 && stage <= 3 && roundData.ranking.map(athleteData =>
        <AthleteScoreSemiL athlete={athleteData} count={count++} />
      )}
      {stage >= 4 && roundData.ranking.map(athleteData =>
        <AthleteScoreFinalL athlete={athleteData} count={count++} />
      )}
    </div>
  )
}

export default ScoresL
