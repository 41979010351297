import React from 'react'

const StatGlossary = () => {
  const statMap = {
    NUM: 'Number of Participations',
    CUR: 'Current Elo Rating',
    PEAK: 'Peak Elo Rating',
    WIN: 'Wins',
    'WIN%': 'Win Rate',
    POD: 'Podiums (Top 3)',
    'POD%': 'Podium Rate',
    FIN: 'Finals Apperances',
    'FIN%': 'Finals Rate',
    SF: 'Semi-Finals Apperances',
    'SF%': 'Semi-Finals Rate'
  }

  return (
    <div className='stat-glossary'>
      <h3 style={{marginTop: '0px', marginBottom: '0.5vh'}}>Glossary + Notes</h3>
      <table>
        <tbody>
          {Object.keys(statMap).map(stat => 
            <tr style={{fontSize: '12px'}}>
              <td style={{ paddingLeft: '0px' }}><h3 style={{margin: '0px'}}>{stat}:</h3></td>
              <td>{statMap[stat]}</td>
            </tr>
          )}
        </tbody>
      </table>
      <div style={{ height: '10px' }}></div>
      <div style={{ fontSize: '12px' }}>*Current Elo only displays active athletes*</div>
      <div style={{ fontSize: '12px' }}>*Active defined as having competed post-2019*</div>
    </div>
  )
}

export default StatGlossary
