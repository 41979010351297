import React from 'react'

const SeasonDropdown = ({ data, close, setCurStat }) => {

  const onClick = (curStat) => {
    setCurStat(curStat)
    close()
  }

  return (
    <div className='stat-dropdown'>
      {data.map(stat =>
        <div className='stat-drop-item' onClick={e => onClick(stat)}>{stat}</div>
      )}
    </div>
  )
}

export default SeasonDropdown
