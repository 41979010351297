const base = process.env.REACT_APP_API_URL

const exceptionHandler = (e) => {
  console.log(e)
  return [404, {}]
}

// POST request for creating a new fantasy team
export async function createTeam(body) {
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('jwt'),
    },
    body: JSON.stringify(body),
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}users/current/teams`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// PUT request for updating a fantasy team
export async function updateTeam(body, fantasyEventId) {
  const options = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('jwt'),
    },
    body: JSON.stringify(body),
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}users/current/teams/${fantasyEventId}`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET all the teams for a given fantasy event
export async function getEventTeams(fantasy_event_id) {
  const options = {
    headers: {'Content-type': 'application/json'},
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}fantasyevents/${fantasy_event_id}/teams`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET the number of teams for each fantasy event
export async function getEventsTeamCount() {
  const options = {
    headers: {'Content-type': 'application/json'},
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}fantasyevents/teams/count`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET all the teams for a given fantasy event
export async function getCurrentUserTeams() {
  const options = {
    headers: {
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('jwt'),
    },
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}users/current/teams`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

// GET a user's team from an event
export async function getMyEventTeam(fantasy_event_id) {
  const options = {
    headers: {
      'Content-type': 'application/json',
      'Authorization': localStorage.getItem('jwt'),
    },
    credentials: 'include'
  }
  try {
    const response = await fetch(`${base}users/current/teams/${fantasy_event_id}`, options)
    const response_json = await response.json()
    return [response.status, response_json]
  } catch (e) {
    return exceptionHandler(e)
  }
}

