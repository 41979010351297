import { Link, useLocation } from 'react-router-dom'
import { Outlet } from 'react-router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCalendar, faUser } from '@fortawesome/free-solid-svg-icons'

const Fantasy = () => {
  // change color of the current fantasy tab
  const loc = useLocation()
  const color = (route) => loc.pathname.startsWith(route) ? 'green' : 'steelblue'

  return (
    <div>
      <div className='grow-vert-event'>
        <Link className='event-title' to='/fantasy'>Fantasy</Link>
        
        <nav className='grow-horiz'>
          <Link className='fantasy-tab' to='home' style={{background: color('/fantasy/home'), gap: '0.3vw'}}>
            <FontAwesomeIcon icon={faHome}/>
            Home
          </Link>
          <Link className='fantasy-tab' to={'events'} style={{background: color('/fantasy/events'), gap: '0.3vw'}}>
            <FontAwesomeIcon icon={faCalendar}/>
            Events
          </Link>
          <Link className='fantasy-tab' to={'account'} style={{background: color('/fantasy/account'), gap: '0.3vw'}}>
            <FontAwesomeIcon icon={faUser}/>
            My Account
          </Link>
        </nav>

        
      </div>
      <Outlet/>
    </div>
  )
}

export default Fantasy
