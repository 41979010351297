import React from 'react'

const LeadTree = ({ roundData }) => {
  let routes = []
  if ('routes' in roundData) {
    routes = roundData.routes
  } else if ('starting_groups' in roundData) {
    roundData.starting_groups.forEach(group => 
      group.routes.forEach(route => routes.push(route)))
  }

  const findCount = (route, score, top=false) => {
    if (top) {
      return route.tops
    }
    const counts = route.scores
    let count = 0
    if (score in counts) {
      count += counts[score]
    }
    if (score + '+' in counts) {
      count += counts[score + '+']
    }
    return count
  }

  if (!roundData.ranking) {
    return <div/>
  }
  return (
    <div className='lead-tree'>

      <div className='grow-tree'>
        <h1>Tree{routes.length > 1 ? 's' : ''}</h1>
        <span className='grow-horiz-tree'>
        {routes.map(route =>
            <span className='grow-tree'>
            <span className='dot-tree' style={{ width: `${Math.max(0.28, 11 * findCount(route, 0, true) / route.attempts)}vw`}}></span>
              {[...Array(Math.max(43, parseInt(route.high_score) + 2)).keys()].reverse().map(score => 
                <span className='dot-tree' style={{width: `${Math.max(0.28, 11 * findCount(route, score, false) / route.attempts)}vw`
                }}></span>)}
            </span>
        )}
        </span>
      </div>
    </div>
  )
}

export default LeadTree
