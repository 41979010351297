import { useState } from "react"
import { forgotPassword } from "./AuthApiCalls"
import { useNavigate } from 'react-router-dom'

const ForgotPassword = () => {
  const navigate = useNavigate()

  // fields needed for password reset
  const [email, setEmail] = useState('')

  const [errors, setErrors] = useState('')
  // POST request that occurs on click of 'Submit' button
  const onSubmit = async () => {
    const body = {
      email: email
    }
    const [status, response_json] = await forgotPassword(body)
    if (status === 200) {
      navigate('/fantasy/resetpassword')
    } else {
      setErrors('Email could not be found')
      console.log(response_json)
    }
  }

  return (
    <div className='grow-vert-elo'>
      <div className='auth-body-container'>
        <h1 style={{background: 'none', marginBottom: '0px'}}>Reset Your Password</h1>
        Enter the email address associated with your account.
        <input className='form-input' placeholder='Email' onChange={event => setEmail(event.target.value)}></input>
        {errors.length > 0 && <div className="error-message">Email could not be found.</div>}
        <input className='btn' type='submit' onClick={onSubmit}/>
      </div>
    </div>
  )
}

export default ForgotPassword
