import React from 'react'
import DonutChart from 'react-donut-chart'

const Insights = ({roundData, stage}) => {
  let routes = []
  if ('routes' in roundData) {
    routes = roundData.routes
  } else if ('starting_groups' in roundData) {
    roundData.starting_groups.forEach(group => 
      group.routes.forEach(route => routes.push(route)))
  }

  const routeString = stage % 2 === 0 ? 'M' : 'W'
  let routeNum = 0
  
  return (
    <div>
        <div>
          <div className='grow-horiz'>
            <div>
              <h1>Round Insights</h1>
              <div className='inline-legend'>
                <span className='dot-rating-little'></span>
                <span>Round difficulty out of 10 </span>
              </div>
              <div className='inline-legend'>
                <span className='dot-top-little'></span>
                <span>Average Attempts to Top</span>
              </div>
              <div className='inline-legend'>
                <span className='dot-zone-little'></span>
                <span>Average Attempts to Zone</span>
              </div>
            </div>
            <span className='dot-rating'>{myRound(roundData.grade, 1)}</span>
          </div>
          {routes.map(route =>
            <div>
              {routes.length > 5 && routeNum % 5 === 0 && <h1>Group {routeNum === 0 ? 'A' : 'B'}</h1>}
              <div className='route-background'>
                
                <div>
                <h2>{routeString + (routeNum++ % 5 + 1)}</h2>
                <div className='inline'>
                <DonutChart
                  data={[{ label: 'Top Rate', value: route.tops}, 
                        { label: 'No Top Rate', value: route.attempts - route.tops}]}
                  height={125}
                  width={125}
                  legend={false}
                  formatValues={(values, total) => !total ? '--' : `${(values / total * 100).toFixed(0)}%`}/>
                <span className='dot-top'>{route.tops ? myRound(route.top_tries / route.tops, 1) : '--'}</span>
                <DonutChart
                  data={[{ label: 'Zone Rate', value: route.zones },
                  { label: 'No Zone Rate', value: route.attempts - route.zones }]}
                  height={125}
                  width={125}
                  legend={false} 
                  formatValues={(values, total) => !total ? '--' : `${(values / total * 100).toFixed(0)}%`} />
                  
                  <span className='dot-zone'>{route.zones ? myRound(route.zone_tries / route.zones, 1) : '--'}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
    </div>
  )
}

// round x to places number of decimal places
const myRound = (x, places=0) => Math.round(x * Math.pow(10, places)) / Math.pow(10, places)

export default Insights
